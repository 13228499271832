import dashboard from "../DashBoard/Dashboard";

function SocialPill({
  icon,
  text,
  setSelected,
  selected,
  social,
  notifications,
  collapse,
  getPosts,
  title,
}) {
  if (title) {
    return (
      <button
        id={text}
        className="bg-gray-50"
        onClick={(e) => {
          setSelected(e.currentTarget.id);
          try {
            getPosts();
          } catch (err) {
            console.log(err);
          }
        }}
      >
        <div
          className={`rounded-md grid-flow-col inline-grid justify-start relative text-white p-3 ${
            collapse ? "flex" : "w-full"
          } hover:bg-gray-200
            ${
              selected === text
                ? `bg-ss-blue text-white hover:bg-ss-blue`
                : `text-gray-400`
            }`}
        >
          {icon}
          <p className={`${collapse ? "hidden" : ""} self-center ml-2`}>
            {title}
          </p>
          {social && notifications && (
            <div
              className={`${
                collapse
                  ? "absolute bg-red-600 h-4 w-4 text-white text-xs rounded-full top-1 right-1"
                  : "rounded-full p-1 h-6 w-6 text-xs text-white absolute right-1 top-3"
              } ${selected ? `bg-red-500` : `bg-red-300`}`}
            >
              {notifications}
            </div>
          )}
        </div>
      </button>
    );
  } else {
    return (
      <button
        id={text}
        className="bg-gray-50"
        onClick={(e) => {
          setSelected(e.currentTarget.id);
          try {
            getPosts();
          } catch (err) {
            console.log(err);
          }
        }}
      >
        <div
          className={`rounded-md grid-flow-col inline-grid justify-start relative text-white p-3 ${
            collapse ? "flex" : "w-full"
          } hover:bg-gray-200
            ${
              selected === text
                ? `bg-ss-blue text-white hover:bg-ss-blue`
                : `text-gray-400`
            }`}
        >
          {icon}
          <p className={`${collapse ? "hidden" : ""} self-center ml-2`}>
            {text}
          </p>
          {social && notifications && (
            <div
              className={`${
                collapse
                  ? "absolute bg-red-600 h-4 w-4 text-white text-xs rounded-full top-1 right-1"
                  : "rounded-full p-1 h-6 w-6 text-xs text-white absolute right-1 top-3"
              } ${selected ? `bg-red-500` : `bg-red-300`}`}
            >
              {notifications}
            </div>
          )}
        </div>
      </button>
    );
  }
}

export default SocialPill;
