import React from "react";

const LogoutIcon = ({ fill }) => {
  return (
    <>
      <svg
        className={`fill-current text-${fill}`}
        width="28"
        height="25"
        viewBox="0 0 28 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19.4444 13.8889V11.1111H6.94445V6.94444L0 12.5L6.94445 18.0555V13.8889H19.4444Z" />
        <path d="M24.9999 0H12.4999C10.968 0 9.72217 1.24583 9.72217 2.77778V8.33333H12.4999V2.77778H24.9999V22.2222H12.4999V16.6667H9.72217V22.2222C9.72217 23.7542 10.968 25 12.4999 25H24.9999C26.5319 25 27.7777 23.7542 27.7777 22.2222V2.77778C27.7777 1.24583 26.5319 0 24.9999 0Z" />
      </svg>
    </>
  );
};

export default LogoutIcon;
