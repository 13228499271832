import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import DeleteIcon from "../Icons/DeleteIcon";
import PostButton from "../common/PostButton";
import axios from "axios";
import jwt from "jsonwebtoken";
// import OauthHelper from "../../../../backend/utils/OAuthHelper";

export default function CreatePostModal({
  currentUser,
  isOpen,
  setIsOpen,
  newPost,
  setNewPost,
  searchTerm,
  setSearchTerm,
  setPosts,
  allPosts,
  setAnalyse,
  analyse,
  setCurrentIndex,
  keywords,
  setKeywords,
  posts,
  currentIndex,
  analyseIssues,
  highlightColor,
  setHighlightColor,
  user,
  picture,
  type,
}) {
  async function submitPost() {
    // hardcoded values for testing creation of post
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp()]);
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    setIsOpen(false);
  }

  const testPostFB = async () => {
    // hardcoded values for testing creation of post
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp()]);
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    setIsOpen(false);
    // const authHeader = OauthHelper.getAuthHeaderForRequest(request);
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const getToken = async () => {
        const formData = new FormData();
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            formData.append("username", currentUser.name);
            formData.append("email", currentUser.email);
            formData.append("desc", newPost);
            formData.append("platform", "twitter");

            if (imgPreviews != null) {
              for (let i = 0; i < imgPreviews.length; i++) {
                formData.append("photo", imgPreviews[i]);
              }
            }

            await axios.post(
              // `twitter/post/${decoded.id}`,
              // `twitter/upload/image/${decoded.id}`,
              `twitter/upload/multiImage/${decoded.id}`,

              formData,
              config
            );
            setImgPreviews();

            // let currentUser = res.data;

            // console.log('currentUser', currentUser);
          } catch (err) {
            // console.log(err);
            // localStorage.removeItem("authToken");
            alert("only 4 images max");
            setImgPreviews();
            // window.location.href = "/";
          }
        }
      };

      /* Facebook post axios
			await axios.post(
				'facebook/post',
				{
					body: newPost,
				},
				config
			);
			*/
      // for posting '/' in posts.route

      //   for twitter

      // await axios.post(
      //   "twitter/post/",
      //   {
      //     username: currentUser.name,
      //     email: currentUser.email,
      //     desc: newPost,
      //     platform: "twitter",
      //   },
      //   config
      // );
      // window.location.replace("/");
      getToken();
    } catch (error) {
      console.log("Post is empty");
    }

    //   await axios.post(
    //     "api/post/",
    //     {
    //       username: currentUser.name,
    //       email: currentUser.email,
    //       desc: newPost,
    //     },
    //     config
    //   );
    //   window.location.replace("/");
    // } catch (error) {
    //   console.log("Post is empty");
    // }
  };
  const randomTest = async () => {
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp()]);
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    setIsOpen(false);
    console.log("press");
    try {
      const getToken = async () => {
        const data = new FormData();
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            console.log("inside");
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            data.append("desc", newPost);

            if (imgPreviews != null) {
              for (let i = 0; i < imgPreviews.length; i++) {
                data.append("photo", imgPreviews[i]);
              }
            }
            // config
            // var config = {
            //   method: "post",
            //   url: `twitter/draft/post/${decoded.id}`,
            //   headers: {
            //     ...data.getHeaders(),
            //   },
            //   data: data,
            // };
            let config = {
              method: "post",
              url: "twitter/draft/post/61f307f0fb6b7c94d6dd73bb",

              data: data,
            };
            // axios
            axios(config)
              .then((response) => {
                console.log("done!");
              })
              .catch((error) => {
                console.log("doesnt work");
              });
          } catch (err) {
            console.log("hmm", err);
            // window.location.href = "/";
          }
        }
      };
      getToken();
    } catch (error) {}
  };

  const saveDraft = async () => {
    console.log("hm saving draft");
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp()]);
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    setIsOpen(false);
    try {
      const getToken = async () => {
        const formData = new FormData();
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            formData.append("desc", newPost);

            if (imgPreviews != null) {
              for (let i = 0; i < imgPreviews.length; i++) {
                formData.append("photo", imgPreviews[i]);
              }
            }
            // config
            var config = {
              method: "post",
              url: `twitter/draft/post/${decoded.id}`,
              headers: {
                ...formData.getHeaders(),
              },
              data: formData,
            };
            // axios
            axios(config)
              .then((response) => {
                console.log("done!");
              })
              .catch((error) => {
                console.log("doesnt work");
              });
          } catch (err) {
            window.location.href = "/";
          }
        }
      };
      getToken();
    } catch (err) {
      console.log("doesnt work");
    }
  };

  // showing/hiding React Highlighter component based on post edit
  const [hideTextbox, setHideTextbox] = useState(false);

  const startAnalyse = async () => {
    // pushes new item to array each time start analyse is clicked...will be changed based on API calls
    // let userPost = [
    //   newPost,
    //   ["Bob", "Sally", "Ryan"],
    //   ["bob@example.org", "abc@example.org", "ya", "All", "all", "ja"],
    // ];
    // console.log(newPost);
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      let data = JSON.stringify({
        desc: newPost,
      });

      var startAnalyseconfig = {
        method: "post",
        url: `twitter/check/and/tell/${decoded.id}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(startAnalyseconfig)
        .then(async (response) => {
          console.log("hmm this is data", response.data);
          const userPost = [
            response.data[0],
            response.data[1],
            response.data[2],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            response.data[3],
            // response.data[4],
          ];
          await setPosts([...allPosts, userPost]);
          await setCurrentIndex(allPosts.length);
          await setHideTextbox(true);
          await setAnalyse(!analyse);
          await setKeywords(
            [...response.data[1], ...response.data[2]].map((word) =>
              RegExp(`\\b${word}\\b`)
            )
          );
        })
        .catch((error) => {});
    }

    // await setPosts([...allPosts, userPost]);
    // await setCurrentIndex(allPosts.length);
    // await setHideTextbox(true);
    // await setAnalyse(!analyse);
    // await setKeywords(
    //   ["bob@example.org", "abc@example.org", "ya", "All", "all", "ja"].map(
    //     (word) => RegExp(`\\b${word}\\b`)
    //   )
    // );
  };

  useEffect(() => {
    fetchProfileDetails();
    if (analyse) {
      setKeywords([new RegExp()]);
      setHideTextbox(true);
      // ["bob@example.org", "abc@example.org", "ya", "All", "all", "ja"].map(
      //   (word) => RegExp(`\\b${word}\\b`)
      // )
    } else {
      setKeywords([new RegExp()]);
      setHideTextbox(false);
    }
  }, [analyse, currentIndex, posts, setKeywords]);

  // timer for button
  const [timer, setTimer] = useState();
  const [timerOn, setTimerOn] = useState(false);
  // use hidden buttons with refs for file upload since they are can't be styled
  const hiddenImgInput = useRef(null);
  const hiddenVideoInput = useRef(null);

  // stores the FileObject for images uploaded by user
  const [imgPreviews, setImgPreviews] = useState();

  const fetchProfileDetails = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      //axios to fetch information
      var profFetchConfig = {
        method: "get",
        url: `api/user/profile/${decoded.id}`,
      };
      axios(profFetchConfig)
        .then((response) => {
          setTimer(response.data.timerData);
        })
        .catch((err) => {
          alert("error fetching");
        });
    }
  };

  const handleImage = () => {
    hiddenImgInput.current.click();
  };

  const handleImgPreview = async (event) => {
    await setImgPreviews(event.target.files);
  };

  const handleVideo = () => {
    hiddenVideoInput.current.click();
  };
  const handleClose = async () => {
    await setNewPost("");
    await setKeywords([new RegExp()]);
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    setIsOpen(false);
  };

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (!timerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerOn]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          static
          as="div"
          open={false}
          className="fixed inset-0 overflow-y-auto"
          onClose={async () => {
            await setNewPost("");
            await setKeywords([new RegExp()]);
            await setHideTextbox(false);
            await setTimerOn(false);
            // await setTimer(5);
            await setAnalyse(false);
            setIsOpen(false);
          }}
        >
          <div className="min-h-full px-4 ml-40 overflow-hidden 2xl:ml-0 2xl:text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed bg-black opacity-60 inset-0 top-20" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-4/6 2xl:w-3/6 p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <button onClick={handleClose}>close</button>
                <div className="mx-10 grid grid-flow-row sm:mt-10">
                  <div className="flex flex-row gap-x-4 relative">
                    <div className="inline-flex relative">
                      <img className="" width={80} src={picture} alt="user" />
                      {type == "Twitter" ? (
                        <img
                          className="absolute bottom-0 -right-1"
                          src="./imgs/twitter.svg"
                          alt="twitter icon"
                        />
                      ) : type == "Instagram" ? (
                        <img
                          width={30}
                          className="absolute bottom-0 -right-1"
                          src="./imgs/instagram.svg"
                          alt="instagram icon"
                        />
                      ) : (
                        <img
                          className="absolute bottom-0 -right-1"
                          src="./imgs/facebook.svg"
                          alt="facebook icon"
                        />
                      )}
                    </div>

                    <div className="flex flex-col text-gray-400">
                      <p className="text-ss-blue text-lg font-bold">{user}</p>
                    </div>
                    <button
                      onClick={startAnalyse}
                      className="ml-auto flex justify-end self-end"
                    >
                      <img
                        width="100"
                        src={
                          analyse
                            ? "./imgs/analyseButtonT.svg"
                            : "./imgs/analyseButtonF.svg"
                        }
                        alt="analyse false"
                      />
                    </button>
                  </div>

                  <div className="flex flex-col gap-y-4 mt-8">
                    <textarea
                      type="text"
                      placeholder={`What's on your mind, ${currentUser.name}?`}
                      id="post"
                      value={newPost}
                      onChange={(event) => setNewPost(event.target.value)}
                      className={`${
                        hideTextbox ? "hidden" : ""
                      } mt-2 leading-relaxed w-full self-center rounded-lg text-gray-800 bg-gray-50 border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50`}
                    ></textarea>
                    <div
                      className={`${hideTextbox ? "" : "hidden"}`}
                      onClick={() => setHideTextbox(false)}
                    >
                      <Highlighter
                        caseSensitive={true}
                        searchWords={keywords}
                        textToHighlight={newPost}
                        highlightStyle={{
                          backgroundColor: highlightColor,
                          borderRadius: "5px",
                          paddingLeft: "4px",
                          paddingRight: "4px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="inline-flex mt-8">
                    <div onClick={handleVideo}>
                      <PostButton
                        btnIcon="./imgs/video.svg"
                        btnText="Upload Videos"
                      />
                      <input
                        type="file"
                        accept="video/*"
                        ref={hiddenVideoInput}
                        hidden
                      />
                    </div>
                    <div onClick={handleImage}>
                      <PostButton
                        btnIcon="./imgs/photo.svg"
                        btnText="Upload Photos"
                      />
                      <input
                        onChange={(event) => handleImgPreview(event)}
                        type="file"
                        accept="image/*"
                        multiple
                        ref={hiddenImgInput}
                        hidden
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-y-4 gap-x-8">
                    {imgPreviews &&
                      [...imgPreviews].map((img, index) => {
                        return (
                          <div className="relative">
                            <img
                              key={index}
                              src={URL.createObjectURL(img)}
                              alt="preview"
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>

                {analyseIssues ? (
                  <>
                    <div
                      className={` ${
                        timer <= 0 ? "hidden" : "flex"
                      } mt-4 justify-end gap-x-4`}
                    >
                      <button
                        type="button"
                        className="inline-flex justify-center px-6 py-2 text-sm font-medium text-gray-500 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={testPostFB}
                      >
                        Post Now
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center px-6 py-2 text-sm font-medium text-gray-500 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={saveDraft}
                      >
                        Post Now
                      </button>

                      <button
                        type="button"
                        onClick={() => setTimerOn(true)}
                        disabled={timerOn}
                        className="inline-flex justify-center px-4  mr-10 py-2 disabled:opacity-50 disabled:cursor-default text-sm font-medium text-white bg-ss-blue border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        <div className="inline-flex gap-x-3">
                          <img src="/imgs/timer.svg" alt="timer" />
                          <p className=" self-center">{timer}s Post</p>
                        </div>
                      </button>
                    </div>
                    <div
                      className={`mt-4 mr-10 ${
                        timer > 0 ? "hidden" : "flex"
                      } justify-end gap-x-4`}
                    >
                      <button
                        type="button"
                        className="inline-flex justify-center px-8 py-2 text-sm font-medium text-white bg-ss-hotpink border border-transparent rounded-md hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={testPostFB}
                      >
                        Post
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center px-6 py-2 text-sm font-medium text-gray-500 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        onClick={saveDraft}
                      >
                        Post Now
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="mt-4 flex justify-end gap-x-4 mr-10">
                    <button
                      type="button"
                      className="inline-flex justify-center px-8 py-2 text-sm font-medium text-white bg-ss-analyseGreen border border-transparent rounded-md hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={testPostFB}
                    >
                      Post
                    </button>
                    <button
                      type="button"
                      // className="inline-flex justify-center px-8 py-2 text-sm font-medium text-white bg-ss-analyseGreen border border-transparent rounded-md hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={randomTest}
                    >
                      Save Draft
                    </button>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
