import DeleteIcon from "../Icons/DeleteIcon";
import TripleDots from "../Icons/TripleDots";

function BigPolarity({ issue, deleteEmail, setKeywords }) {
  return (
    <div className="bg-white grid gap-y-4 mt-3 rounded-xl shadow-xl py-4 px-2">
      <div className="flex gap-x-3">
        <img src="/imgs/emotions.svg" alt="emotion" />
        <p className="font-medium self-center">Sentiment</p>
        <p className="text-xs text-gray-500 self-center">Negative Polarity</p>
      </div>
      <div className="ml-10 flex flex-col gap-y-2">
        <p className="text-base">My goodness wth.</p>
        <p className="max-w-xs text-xs">
          The line is potentially subjective in nature and likely contains some
          sentimentally-charged opinion.
        </p>
      </div>
      <div className=" inline-flex ml-10 gap-x-2">
        <div className="text-ss-hotpink  max-w-max py-2 px-3 font-semibold rounded-lg bg-gray-200">
          <p>Negative polarity detected</p>
        </div>
        <div className="text-white max-w-max py-2 px-3 rounded-lg font-semibold bg-ss-hotpink">
          <p>Shock</p>
        </div>
      </div>
      <div className="flex justify-end mr-4 mt-4 gap-x-4">
        <div>
          <DeleteIcon />
        </div>
        <TripleDots />
      </div>
    </div>
  );
}

export default BigPolarity;
