import React from "react";

const TwitterIcon = ({ fill, mobile, notifications }) => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 2.6096C24.0806 3.05059 23.093 3.34856 22.0547 3.48324C23.126 2.78933 23.9275 1.69719 24.3097 0.410618C23.3031 1.05779 22.2016 1.51332 21.0528 1.75742C20.2803 0.864556 19.2571 0.272751 18.142 0.0738863C17.027 -0.124978 15.8825 0.080224 14.8862 0.657635C13.8899 1.23505 13.0976 2.15236 12.6323 3.26716C12.167 4.38195 12.0547 5.63186 12.3128 6.82283C10.2734 6.71198 8.27824 6.13817 6.45691 5.13862C4.63558 4.13907 3.02876 2.73613 1.74073 1.02085C1.30032 1.84324 1.04708 2.79672 1.04708 3.81219C1.04659 4.72634 1.25455 5.62649 1.65251 6.43277C2.05048 7.23906 2.62614 7.92654 3.32842 8.43423C2.51396 8.40617 1.71747 8.16795 1.00524 7.73937V7.81088C1.00516 9.09302 1.41486 10.3357 2.16483 11.3281C2.91479 12.3204 3.95883 13.0014 5.11979 13.2553C4.36425 13.4767 3.57211 13.5093 2.80322 13.3507C3.13078 14.4539 3.76882 15.4186 4.62804 16.1097C5.48725 16.8009 6.52462 17.1839 7.59491 17.2051C5.77803 18.7491 3.53418 19.5866 1.22435 19.5829C0.815182 19.583 0.406364 19.5571 0 19.5054C2.34462 21.1373 5.07392 22.0034 7.86136 22C17.2972 22 22.4555 13.5402 22.4555 6.20306C22.4555 5.96469 22.45 5.72393 22.4401 5.48556C23.4435 4.7001 24.3096 3.72744 24.9978 2.61318L25 2.6096Z"
        fill={fill}
      />
    </svg>
  );
};

export default TwitterIcon;
