import PasswordUpdate from "./PasswordUpdate";
import AccountSidebar from "./AccountSidebar";
import NavBar from "../common/NavBar";
import NotificationSettings from "./NotificationSettings";
import ProfileSettings from "./ProfileSettings";
import AccountSettings from "./AccountSettings";
import { useEffect, useState } from "react";
import MobileSettings from "./MobileSettings";
import axios from "axios";
import jwt from "jsonwebtoken";

import langPref from "../../util/langPref";

function MyAccount() {
  const [width, setWidth] = useState(window.innerWidth);

  const [profile_setting, setProfile_setting] = useState("");
  const [account_setting, setAccount_setting] = useState("");
  const [notification_setting, setNotification_setting] = useState("");
  const [password_update, setPassword_update] = useState("");
  const [logout, setLogout] = useState("");

  const fetchProfileDetails = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      //axios to fetch information
      var profFetchConfig = {
        method: "get",
        url: `api/user/profile/${decoded.id}`,
      };
      axios(profFetchConfig)
        .then((response) => {
          const dataList = langPref(response.data.languagePref);
          setProfile_setting(dataList[0].profile_setting);
          setAccount_setting(dataList[0].account_setting);
          setNotification_setting(dataList[0].notification_setting);
          setPassword_update(dataList[0].password_update);
          setLogout(dataList[0].logout);

          // console.log(response.data.timeFormat);
        })
        .catch((err) => {});
    }
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const logoutHandler = async () => {
    try {
      await axios.get("api/auth/logout");
      localStorage.removeItem("authToken");
      window.location.href = "/dashboard";
    } catch (error) {
      console.log(error);
    }
  };

  const isMobile = width <= 768;
  const intialScreen = isMobile ? "Mobile Settings" : "Profile Settings";
  const [selected, setSelected] = useState(intialScreen);

  const renderSwitch = (params) => {
    switch (params) {
      case "Profile Settings":
        return (
          <ProfileSettings selected={selected} setSelected={setSelected} />
        );
      case "Account Settings":
        return (
          <AccountSettings selected={selected} setSelected={setSelected} />
        );
      case "Notification Settings":
        return (
          <NotificationSettings selected={selected} setSelected={setSelected} />
        );
      case "Password Update":
        return <PasswordUpdate selected={selected} setSelected={setSelected} />;
      case "Mobile Settings":
        return <MobileSettings selected={selected} setSelected={setSelected} />;
      case "Logout":
        return logoutHandler();
      default:
        if (isMobile) {
          return <MobileSettings />;
        } else {
          return <ProfileSettings />;
        }
    }
  };
  return (
    <>
      <div className="hidden sm:block">
        <NavBar />
      </div>
      <div className="flex">
        <AccountSidebar selected={selected} setSelected={setSelected} />
        {renderSwitch(selected)}
      </div>
    </>
  );
}

export default MyAccount;
