import { Redirect, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const getexistUser = async () => {
      const existToken = localStorage.getItem("authToken");
      if (!existToken) {
        try {
          const res = await axios.get("api/auth/success");
          let currentUser = res.data.currentUser;
          let token = currentUser.token;
          localStorage.setItem("authToken", token);
          setIsLoggedIn(true);
          window.location.href = "/dashboard";
        } catch (error) {
          console.log("You are not authorized");
          localStorage.removeItem("authToken");
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(true);
      }
    };
    getexistUser();
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("authToken") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
