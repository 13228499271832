import React from "react";

const Dashboard = ({ fill, mobile, notifications }) => {
  return (
    <>
      <div className="relative">
        <svg
          width="25"
          height="27"
          viewBox="0 0 25 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.38889 15H9.72222C10.0906 15 10.4438 14.842 10.7043 14.5607C10.9648 14.2794 11.1111 13.8978 11.1111 13.5V1.5C11.1111 1.10218 10.9648 0.720644 10.7043 0.43934C10.4438 0.158035 10.0906 0 9.72222 0H1.38889C1.02053 0 0.667263 0.158035 0.406796 0.43934C0.146329 0.720644 0 1.10218 0 1.5V13.5C0 13.8978 0.146329 14.2794 0.406796 14.5607C0.667263 14.842 1.02053 15 1.38889 15ZM0 25.5C0 25.8978 0.146329 26.2794 0.406796 26.5607C0.667263 26.842 1.02053 27 1.38889 27H9.72222C10.0906 27 10.4438 26.842 10.7043 26.5607C10.9648 26.2794 11.1111 25.8978 11.1111 25.5V19.5C11.1111 19.1022 10.9648 18.7206 10.7043 18.4393C10.4438 18.158 10.0906 18 9.72222 18H1.38889C1.02053 18 0.667263 18.158 0.406796 18.4393C0.146329 18.7206 0 19.1022 0 19.5V25.5ZM13.8889 25.5C13.8889 25.8978 14.0352 26.2794 14.2957 26.5607C14.5562 26.842 14.9094 27 15.2778 27H23.6111C23.9795 27 24.3327 26.842 24.5932 26.5607C24.8537 26.2794 25 25.8978 25 25.5V15C25 14.6022 24.8537 14.2206 24.5932 13.9393C24.3327 13.658 23.9795 13.5 23.6111 13.5H15.2778C14.9094 13.5 14.5562 13.658 14.2957 13.9393C14.0352 14.2206 13.8889 14.6022 13.8889 15V25.5ZM15.2778 10.5H23.6111C23.9795 10.5 24.3327 10.342 24.5932 10.0607C24.8537 9.77936 25 9.39782 25 9V1.5C25 1.10218 24.8537 0.720644 24.5932 0.43934C24.3327 0.158035 23.9795 0 23.6111 0H15.2778C14.9094 0 14.5562 0.158035 14.2957 0.43934C14.0352 0.720644 13.8889 1.10218 13.8889 1.5V9C13.8889 9.39782 14.0352 9.77936 14.2957 10.0607C14.5562 10.342 14.9094 10.5 15.2778 10.5Z"
            fill={fill}
          />
        </svg>
      </div>
    </>
  );
};

export default Dashboard;
