import DeleteIcon from "../Icons/DeleteIcon";
import PrivacyIcon from "../Icons/PrivacyIcon";
import TripleDots from "../Icons/TripleDots";
import axios from "axios";
import jwt from "jsonwebtoken";
import { useState, Fragment, useEffect, useRef } from "react";

function PrivacyCard({
  issue,
  deleteEmail,
  setKeywords,
  carouselIndex,
  arrayLength,
  setCarouselIndex,
  setHighlightColor,
}) {
  const handleDelete = async () => {
    // for big card on the top (email)
    // to prevent carousel from displaying empty slide change the current index if it is the last item
    if (carouselIndex === arrayLength - 1 && arrayLength > 1) {
      await setCarouselIndex(carouselIndex - 1);
    }
    await deleteEmail(issue);
  };

  const handle3Dot = async () => {
    // for big card on the top (email)
    // to prevent carousel from displaying empty slide change the current index if it is the last item
    if (carouselIndex === arrayLength - 1 && arrayLength > 1) {
      await setCarouselIndex(carouselIndex - 1);
    }
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );

      var data = JSON.stringify({
        value: issue,
      });

      var config = {
        method: "post",
        url: `api/user/individual/filter/${decoded.id}/Email/Add`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(async (response) => {
          await deleteEmail(issue);
        })
        .catch(() => {
          alert("error");
        });
    }
  };

  const handleClick = async () => {
    setHighlightColor("rgba(249, 166, 17, 0.5)");
    await setKeywords([RegExp(`\\b${issue}\\b`)]);
  };
  return (
    <div
      onClick={handleClick}
      className="bg-white cursor-pointer hover:bg-blue-50 grid gap-y-4 mt-12 rounded-xl shadow-xl py-4 px-2"
    >
      <div className="flex gap-x-3">
        <PrivacyIcon />
        <p className="font-medium self-center">Privacy Issues</p>
        <p className="text-xs text-gray-500 self-center">
          Potential leaking term
        </p>
      </div>
      <div className="text-white ml-10 max-w-max p-2 rounded-lg bg-yellow-400">
        <p>{issue}</p>
      </div>
      <div className="flex justify-start">
        <p className="font-semibold ml-10">Email Detected</p>
      </div>
      <div className="flex justify-end mr-4 gap-x-4">
        <div onClick={handleDelete}>
          <DeleteIcon />
        </div>
        <div onClick={handle3Dot}>
          <TripleDots />
        </div>
      </div>
    </div>
  );
}

export default PrivacyCard;
