import DeleteIcon from "../Icons/DeleteIcon";
import TripleDots from "../Icons/TripleDots";
import axios from "axios";
import jwt from "jsonwebtoken";
import { useState, Fragment, useEffect, useRef } from "react";

function PolarityCard({ issue, score, deleteEntry }) {
  const handleDelete = async () => {
    // links
    await deleteEntry(issue);
  };

  const handle3Dot = async () => {
    // links
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      var data = JSON.stringify({
        value: issue,
      });

      var config = {
        method: "post",
        url: `api/user/individual/filter/${decoded.id}/Link/Add`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(async (response) => {
          // console.log(issue);
          await deleteEntry(issue);
          // alert("done");
        })
        .catch(() => {
          alert("error");
        });
    }
  };

  return (
    <div
      className={`${
        issue ? "cursor-pointer" : ""
      } flex flex-row gap-x-4 bg-white mt-2 rounded-lg p-4`}
    >
      <img src="/imgs/emotions.svg" alt="emotion" />
      <p className="font-normal self-center">
        {issue ? issue : "Overall Negative Polarity"}
        {/* {score ? "Overall Negative Polarity" : issue} */}
      </p>
      <div className="text-xs text-gray-500 self-center">
        {score ? (
          <div className="inline-flex gap-x-0.5">
            <p className="self-center">Score</p>
            <p className="text-lg font-medium">{score}</p>
          </div>
        ) : (
          <p>Negative Polarity</p>
        )}
      </div>
      {issue && (
        <div className="flex flex-grow justify-end gap-x-4 self-center">
          <div onClick={handleDelete}>
            <DeleteIcon />
          </div>
          <div onClick={handle3Dot}>
            <TripleDots />
          </div>
        </div>
      )}
    </div>
  );
}

export default PolarityCard;
