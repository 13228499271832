import React from "react";

const PrivacyIcon = ({ selected }) => {
  return (
    <svg
      className={`fill-current ${
        selected ? "text-white" : "text-ss-document-blue"
      }`}
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M13.0003 3.18109L2.88916 7.69851V14.5255C2.88916 21.091 7.1936 27.1481 13.0003 28.9493C18.8069 27.1627 23.1114 21.091 23.1114 14.5255V7.69851L13.0003 3.18109ZM14.4447 23.2408H11.5558V14.5255H14.4447V23.2408ZM14.4447 11.6204H11.5558V8.7153H14.4447V11.6204Z"
      />
      <path d="M13 3.18108L23.1111 7.6985V14.5255C23.1111 21.091 18.8067 27.1481 13 28.9493C7.19333 27.1481 2.88889 21.091 2.88889 14.5255V7.6985L13 3.18108ZM13 0L0 5.81019V14.5255C0 22.5871 5.54667 30.1258 13 31.9561C20.4533 30.1258 26 22.5871 26 14.5255V5.81019L13 0ZM11.5556 8.71529H14.4444V11.6204H11.5556V8.71529ZM11.5556 14.5255H14.4444V23.2408H11.5556V14.5255Z" />
    </svg>
  );
};

export default PrivacyIcon;
