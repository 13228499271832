import { useState, Fragment, useEffect, useRef } from "react";
import { Switch } from "@headlessui/react";
import { Listbox, Transition } from "@headlessui/react";
import jwt from "jsonwebtoken";
import axios from "axios";
import langPref from "./../../util/langPref";
function ProfileSettings({ selected, setSelected }) {
  const [enabled, setEnabled] = useState();

  const languages = [
    { language: "English" },
    { language: "Mandarin" },
    { language: "German" },
    { language: "Japanese" },
  ];

  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [language, setLanguage] = useState("");

  const [profile_setting, setProfile_setting] = useState("");
  const [basic_information, setBasic_information] = useState("");
  const [nameTitle, setNameTitle] = useState("");
  const [emailTitle, setEmailTitle] = useState("");
  const [languageTitle, setLanguageTitle] = useState("");
  const [language_and_time, setLanguage_and_time] = useState("");
  const [update_language, setUpdate_language] = useState("");

  const [time, setTime] = useState("");
  const [time_details_1, setTime_details_1] = useState("");
  const [time_details_2, setTime_details_2] = useState("");

  const [timer, setTimer] = useState("");
  const [timer_details_1, setTimer_details_1] = useState("");
  const [timer_details_2, setTimer_details_2] = useState("");
  const [timer_details_3, setTimer_details_3] = useState("");

  const [whitelistTerm, setWhitelistTerm] = useState("");
  const [whitelistTerm_details, setWhitelistTermDetail] = useState("");

  const [analyze_option, setAnalyze_option] = useState("");
  const [analyze_option_details_1, setAnalyze_option_details_1] = useState("");
  const [analyze_option_details_2, setAnalyze_option_details_2] = useState("");
  const [analyze_option_details_3, setAnalyze_option_details_3] = useState("");
  const [analyze_option_details_4, setAnalyze_option_details_4] = useState("");

  const [added_whitelist, setAdded_whitelist] = useState("");
  const [added_whitelist_detail_1, setAdded_whitelist_detail_1] = useState("");
  const [added_whitelist_detail_2, setAdded_whitelist_detail_2] = useState("");

  const [list_of_name, setList_of_name] = useState("");
  const [list_of_email, setList_of_email] = useState("");
  const [list_of_links, setList_of_links] = useState("");

  const [removeText, setRemoveText] = useState("");

  const [whitelistGroup, setWhitelistGroup] = useState([]);

  const [timerValue, setTimerValue] = useState("");

  /// refs
  const timerRef = useRef();
  const whiteListTermRef = useRef();
  const [errorState, setErrorState] = useState("");

  /// analsyze options
  const [emailOption, setEmailOption] = useState(false);
  const [nameOption, setNameOption] = useState(false);

  // individual analyzing
  const [nameList, setNameList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [linkList, setLinkList] = useState([]);

  const removeTerms = (value, type) => {
    // value is the term
    // type is the type of the value (email, name,link...etc)
    return () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        var data = JSON.stringify({
          value: value,
        });

        var removeConfig = {
          method: "post",
          url: `api/user/individual/filter/${decoded.id}/${type}/Remove`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(removeConfig)
          .then((response) => {
            // maybe new value of the terms can be fetched and update the state
            setNameList(response.data.Name);
            setEmailList(response.data.Email);
            setLinkList(response.data.Link);
          })
          .catch(() => {
            alert("error");
          });
      }
    };
  };

  const addNewTerms = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );

      var data = JSON.stringify({
        value: whiteListTermRef.current.value,
      });

      var config = {
        method: "post",
        url: `api/user/add/new/filter/${decoded.id}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(config)
        .then((response) => {
          setNameList(response.data.Name);
          setEmailList(response.data.Email);
          setLinkList(response.data.Link);
        })
        .catch((e) => {
          alert("error adding value inside, maybe unsupported category");
        });
    }
  };

  const analyzeOptionsWrapper = (value) => {
    return () => {
      // filter according to the value
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        if (value === "Email") {
          // check Email boolean value
          //call toekn value
          var emailConfig = {
            method: "get",
            url: `api/user/add/filter/option/${
              decoded.id
            }/${value}/${!emailOption}`,
          };
          axios(emailConfig)
            .then((repsonse) => {
              // console.log("s");
              fetchProfileDetails();
            })
            .catch((error) => {
              alert("Erorr");
            });

          // console.log(!emailOption);
        } else if (value === "Name") {
          // check Name boolean value
          var nameConfig = {
            method: "get",
            url: `api/user/add/filter/option/${
              decoded.id
            }/${value}/${!nameOption}`,
          };
          axios(nameConfig)
            .then((repsonse) => {
              // console.log("s");
              fetchProfileDetails();
            })
            .catch((error) => {
              alert("Erorr");
            });
        }
      }
    };
  };

  const submitTimerSetting = (e) => {
    e.preventDefault();
    const enteredTimerRef = timerRef.current.value;
    const checker = new RegExp(/\d+/);
    if (checker.test(enteredTimerRef)) {
      // do sth
      /// check length
      // this can be done together on top but
      // this is better option as it can use to give user better error description
      const lengthCheck = new RegExp(/\d{2}/);
      if (
        (lengthCheck.test(enteredTimerRef) && enteredTimerRef <= 60) ||
        enteredTimerRef.length == 1
      ) {
        /// get the token value out
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          const decoded = jwt.verify(
            token,
            "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
          );
          // call api and update accordinggly
          setErrorState("");
          var config = {
            method: "get",
            url: `api/user/timer/update/${decoded.id}/${enteredTimerRef}`,
          };
          axios(config)
            .then(() => {
              alert("done!");
            })
            .catch((err) => {
              /// axios error
              alert("Error updating timer setting");
            });
        }
      } else {
        setErrorState("Number Ranging from 0-60 is allowed!");
        // alert("Number Ranging from 0-60 is allowed!");
      }

      // need to do some further checking
    } else {
      setErrorState("Only Number Is Allowed");
      // alert("Only Number Is Allowed");
    }
  };

  //
  const fetchProfileDetails = async () => {
    console.log("start fetch");
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      //axios to fetch information
      var profFetchConfig = {
        method: "get",
        url: `api/user/profile/${decoded.id}`,
      };
      axios(profFetchConfig)
        .then((response) => {
          setName(response.data.name);
          setemail(response.data.email);
          setTimeFormat(response.data.timeFormat);
          setLanguage({ language: response.data.languagePref });
          const dataList = langPref(response.data.languagePref);
          setProfile_setting(dataList[1].profile_setting);
          setBasic_information(dataList[1].basic_information);
          setNameTitle(dataList[1].name);
          setEmailTitle(dataList[1].email);
          setLanguageTitle(dataList[1].language);
          setLanguage_and_time(dataList[1].language_and_time);
          setUpdate_language(dataList[1].update_language);
          setTime(dataList[1].time);
          setTime_details_1(dataList[1].time_details_1);
          setTime_details_2(dataList[1].time_details_2);
          setTimerValue(response.data.timerData);

          setTimer(dataList[1].timer_setting);
          setTimer_details_1(dataList[1].timer_setting_details_1);
          setTimer_details_2(dataList[1].timer_setting_details_2);
          setTimer_details_3(dataList[1].timer_setting_details_3);

          setWhitelistTerm(dataList[1].whitelist_term);
          setWhitelistTermDetail(dataList[1].whitelist_term_details);

          setAnalyze_option(dataList[1].analyze_option);
          setAnalyze_option_details_1(dataList[1].analyze_option_details_1);
          setAnalyze_option_details_2(dataList[1].analyze_option_details_2);
          setAnalyze_option_details_3(dataList[1].analyze_option_details_3);
          setAnalyze_option_details_4(dataList[1].analyze_option_details_4);
          // setWhitelistGroup([
          //   analyze_option_details_3,
          //   analyze_option_details_4,
          // ]);

          setAdded_whitelist(dataList[1].added_whitelist);
          setAdded_whitelist_detail_1(dataList[1].added_whitelist_detail_1);
          setAdded_whitelist_detail_2(dataList[1].added_whitelist_detail_2);

          setList_of_name(dataList[1].list_of_name);
          setList_of_email(dataList[1].list_of_email);
          setList_of_links(dataList[1].list_of_links);

          setRemoveText(dataList[1].remove);

          if (response.data.aGroup) {
            if (response.data.aGroupOption.includes("Email")) {
              setEmailOption(true);
            } else {
              setEmailOption(false);
            }
            if (response.data.aGroupOption.includes("Name")) {
              setNameOption(true);
            } else {
              setNameOption(false);
            }
          } else {
            setEmailOption(false);
            setNameOption(false);
            // dont have
            // dont need to do anything
          }
          // split
          if (response.data.aIndividual) {
            // here i think we can list down the items
            setNameList(response.data.ignoredNameList);
            setEmailList(response.data.ignoredEmailList);
            setLinkList(response.data.ignoredLinkList);
          } else {
            // dont have
            // dont need to do anything
          }

          // console.log(response.data.timeFormat);
          if (response.data.timeFormat == "24") {
            setEnabled(true);
          } else {
            setEnabled(false);
          }
        })
        .catch((err) => {});
    }
  };

  const MangageLanguage = () => {
    // console.log();
    const dataList = langPref(language.language);
    setProfile_setting(dataList[1].profile_setting);
    setBasic_information(dataList[1].basic_information);
    setNameTitle(dataList[1].name);
    setEmailTitle(dataList[1].email);
    setLanguageTitle(dataList[1].language);
    setLanguage_and_time(dataList[1].language_and_time);
    setUpdate_language(dataList[1].update_language);
    setTime(dataList[1].time);
    setTime_details_1(dataList[1].time_details_1);
    setTime_details_2(dataList[1].time_details_2);
  };
  useEffect(() => {
    fetchProfileDetails();
    // MangageLanguage();
    // return () => {
    //   console.log("unmounted");
    // };
  }, []);

  // fetchProfileDetails();

  /// trigger
  const hrTrigger = () => {
    console.log(timeFormat);
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      var config = {
        method: "get",
        url: `api/user/time/trigger/${decoded.id}/${timeFormat}`,
      };
      axios(config)
        .then((response) => {
          setTimeFormat(response.data);
          console.log(response.data);
          if (response.data == "24") {
            setEnabled(true);
          } else {
            setEnabled(false);
          }
        })
        .catch((err) => {});
    }
  };

  const langTrigger = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      var config = {
        method: "get",
        url: `api/user/language/trigger/${decoded.id}/${language.language}`,
      };
      axios(config)
        .then((response) => {
          console.log(response.data);
          setLanguage({ language: response.data });
          MangageLanguage();
          window.location.href = "/myaccount";
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // console.log(language);
  };

  return (
    <div className="flex flex-col sm:flex-row sm:flex-grow w-full">
      <div className="py-4 px-4 flex justify-between sm:hidden shadow-xl text-ss-blue font-semibold">
        <button onClick={() => setSelected("Mobile Settings")}>
          <img
            className="flex transform -rotate-180"
            src="/imgs/arrow.svg"
            alt="arrow"
          />
        </button>
        <h1 className="font-semi-bold flex justify-center ml-auto mr-auto">
          Profile Settings
        </h1>
      </div>
      <div className="mb-40 sm:mb-10 px-4 sm:mx-20 flex flex-grow flex-col w-full gap-y-10">
        <div className="hidden sm:block">
          <h1 className="font-semi-bold">{profile_setting}</h1>
          <hr className="mt-8 border-gray-300" />
        </div>

        <div className="flex flex-col gap-y-4 mt-8 sm:mt-0">
          <h4 className="text-lg font-medium">{basic_information}</h4>
          <div className="inline-flex gap-x-4">
            <img src="/imgs/profile.svg" alt="username" />
            <h6 className="font-medium self-center">{nameTitle}</h6>
          </div>
          <div className="bg-gray-100 rounded-lg ml-8 max-w-max pl-4 py-2 pr-44">
            <p>{name}</p>
          </div>
          <div className="inline-flex gap-x-4">
            <img src="/imgs/email.svg" alt="email" />
            <h6 className="font-medium">{emailTitle}</h6>
          </div>
          <div className="bg-gray-100 rounded-lg ml-8 max-w-max pl-4 py-2 pr-20">
            <p>{email}</p>
          </div>
          <hr className="mt-8 border-gray-300" />
        </div>
        <div className="flex flex-col gap-y-4">
          <h4 className="text-lg font-medium">{language_and_time}</h4>
          <div className="inline-flex gap-x-4">
            <img src="/imgs/language.svg" alt="language" />
            <h6 className="font-medium">{languageTitle}</h6>
          </div>
          <div className=" w-44 ml-8">
            <Listbox
              value={language}
              onChange={setLanguage}
              // onChange={langTrigger}
            >
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full py-3 pl-3 pr-10 text-left bg-gray-100 rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                  <span className="block truncate">{language.language}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <img src="/imgs/dropdownarrow.svg" />
                  </span>
                </Listbox.Button>
                <br></br>
                <br></br>

                <button onClick={langTrigger}>{update_language}</button>

                <br></br>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {languages.map((language, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          `${
                            active
                              ? " text-white bg-ss-document-blue"
                              : "text-gray-900"
                          }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                        }
                        value={language}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`${
                                selected ? "font-medium" : "font-normal"
                              } block truncate`}
                            >
                              {language.language}
                            </span>
                            {selected ? (
                              <span
                                className={`${
                                  active
                                    ? "text-ss-document-blue"
                                    : "text-ss-document-blue"
                                }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                              >
                                <img src="/imgs/check.svg" alt="check" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <div className="inline-flex gap-x-4">
            <img src="/imgs/time.svg" alt="time" />
            <h6 className="font-medium">{time}</h6>
          </div>
          <div className="ml-8">
            <p>{time_details_1}</p>
            <div className="inline-flex w-full">
              <p className="text-gray-500 text-xs self-center">
                {time_details_2}
              </p>
              <div className="flex justify-end flex-grow">
                <Switch
                  checked={enabled}
                  onChange={hrTrigger}
                  className={`${
                    enabled ? "bg-ss-document-blue" : "bg-gray-200"
                  } relative inline-flex items-center h-6 rounded-full w-11`}
                >
                  <span className="sr-only">Enable 24 hr time</span>
                  <span
                    className={`${
                      enabled ? "translate-x-6" : "translate-x-1"
                    } inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full`}
                  />
                </Switch>
              </div>
            </div>
          </div>
          <br></br>
          {/* preference section for timer */}
          <div className="inline-flex gap-x-4">
            <img src="/imgs/time.svg" alt="time" />
            <h6 className="font-medium">{timer}</h6>
          </div>
          <div className="ml-8">
            <p>{timer_details_1}</p>
            <div className="inline-flex w-full">
              <p className="text-gray-500 text-xs self-center">
                {timer_details_2}
              </p>
            </div>
            <form onSubmit={submitTimerSetting}>
              <div className="flex flex-col gap-y-4">
                <br></br>
                <div className="flex flex-row gap-x-4">
                  <input
                    id="current-password"
                    className="bg-gray-100 rounded-lg max-w-max pl-4 py-2 pr-20 border-2 border-indigo-500/50"
                    placeholder={timerValue}
                    ref={timerRef}
                  />
                  <button>{timer_details_3}</button>
                </div>
              </div>
              <div className="text-red-500">
                <p>{errorState}</p>
              </div>

              <br></br>
            </form>
          </div>
          {/* Adding analkyze option */}
          <div className="inline-flex gap-x-4">
            <img src="/imgs/time.svg" alt="time" />
            <h6 className="font-medium">{whitelistTerm}</h6>
          </div>
          <form onSubmit={addNewTerms}>
            <div className="flex flex-col gap-y-4">
              <br></br>
              <div className="flex flex-row gap-x-4">
                <input
                  id="current-password"
                  className="bg-gray-100 rounded-lg max-w-max pl-4 py-2 pr-20 border-2 border-indigo-500/50"
                  ref={whiteListTermRef}
                />
                <button>{whitelistTerm_details}</button>
              </div>
            </div>
            <div className="text-red-500">
              <p>{errorState}</p>
            </div>

            <br></br>
          </form>
          {/* added analyze options */}
          <div className="inline-flex gap-x-4">
            <img src="/imgs/time.svg" alt="time" />
            <h6 className="font-medium">{analyze_option}</h6>
          </div>
          <div className="ml-8">
            <p>{analyze_option_details_1}</p>
            <div className="inline-flex w-full">
              <p className="text-gray-500 text-xs self-center">
                {analyze_option_details_2}
              </p>
            </div>
            {["Email", "Name"].map((value, index) => {
              return (
                <div className="flex ">
                  <p>{value}</p>
                  <br></br>
                  <div className="flex justify-end flex-grow">
                    {value == "Email" ? (
                      <Switch
                        checked={emailOption}
                        onChange={analyzeOptionsWrapper(value)}
                        className={`${
                          emailOption ? "bg-ss-document-blue" : "bg-gray-200"
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                      >
                        <span className="sr-only"></span>
                        <span
                          className={`${
                            emailOption ? "translate-x-6" : "translate-x-1"
                          } inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full`}
                        />
                      </Switch>
                    ) : value == "Name" ? (
                      <Switch
                        checked={nameOption}
                        onChange={analyzeOptionsWrapper(value)}
                        className={`${
                          nameOption ? "bg-ss-document-blue" : "bg-gray-200"
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                      >
                        <span className="sr-only"></span>
                        <span
                          className={`${
                            nameOption ? "translate-x-6" : "translate-x-1"
                          } inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full`}
                        />
                      </Switch>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* Individual elemtn Filter */}
          <div className="inline-flex gap-x-4">
            <img src="/imgs/time.svg" alt="time" />
            <h6 className="font-medium">{added_whitelist}</h6>
          </div>
          <div className="ml-8">
            <p>{added_whitelist_detail_1}</p>
            <div className="inline-flex w-full">
              <p className="text-gray-500 text-xs self-center">
                {added_whitelist_detail_2}
              </p>
            </div>
            <br></br>
            <br></br>
            <div className="font-medium">{list_of_name}</div>

            {nameList.map((value) => {
              return (
                <div>
                  <div className="flex">
                    <p>{value}</p>
                    <div className="flex justify-end flex-grow">
                      <div
                        onClick={removeTerms(value, "Name")}
                        class="opacity-50 hover:opacity-100 cursor-pointer"
                      >
                        {removeText}
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
              );
            })}
            <br></br>
            <br></br>
            <div className="font-medium">{list_of_email}</div>

            {emailList.map((value) => {
              return (
                <div>
                  <div className="flex">
                    <p>{value}</p>
                    <div className="flex justify-end flex-grow">
                      <div
                        onClick={removeTerms(value, "Email")}
                        class="opacity-50 hover:opacity-100 cursor-pointer"
                      >
                        {removeText}
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
              );
            })}
            <br></br>
            <br></br>
            <div className="font-medium">{list_of_links}</div>

            {linkList.map((value) => {
              return (
                <div>
                  <div className="flex">
                    <p>{value}</p>
                    <div className="flex justify-end flex-grow">
                      <div
                        onClick={removeTerms(value, "Link")}
                        class="opacity-50 hover:opacity-100 cursor-pointer "
                      >
                        {removeText}
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSettings;
