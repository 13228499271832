import React from "react";

const TripleDots = ({ selected }) => {
  return (
    <svg
      className={`fill-current ${selected ? "text-white" : "text-gray-400"}`}
      width="5"
      height="21"
      viewBox="0 0 5 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M-5.11265e-05 2.41852C-5.11844e-05 3.75424 1.07435 4.83705 2.39969 4.83705C3.72504 4.83705 4.79944 3.75424 4.79944 2.41852C4.79944 1.08281 3.72504 -4.73311e-08 2.39969 -1.05717e-07C1.07435 -1.64103e-07 -5.10685e-05 1.08281 -5.11265e-05 2.41852Z" />
      <path d="M-5.11265e-05 10.2435C-5.11844e-05 11.5792 1.07435 12.662 2.39969 12.662C3.72504 12.662 4.79944 11.5792 4.79944 10.2435C4.79944 8.90777 3.72504 7.82496 2.39969 7.82496C1.07435 7.82496 -5.10685e-05 8.90777 -5.11265e-05 10.2435Z" />
      <path d="M-5.11265e-05 18.0684C-5.11844e-05 19.4042 1.07435 20.487 2.39969 20.487C3.72504 20.487 4.79944 19.4042 4.79944 18.0684C4.79944 16.7327 3.72504 15.6499 2.39969 15.6499C1.07435 15.6499 -5.10685e-05 16.7327 -5.11265e-05 18.0684Z" />
    </svg>
  );
};

export default TripleDots;
