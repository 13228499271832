import { useState, useEffect, useRef } from "react";
import { Switch } from "@headlessui/react";
import PasswordIcon from "../Icons/PasswordIcon";
import jwt from "jsonwebtoken";
import axios from "axios";

function PasswordUpdate({ selected, setSelected }) {
  const [twoFactor, setTwoFactor] = useState(true);
  const [authenticationEmail, setAuthenticationEmail] = useState(false);
  const [sms, setSms] = useState(false);

  const [accountType, setAccountType] = useState("");

  const [emailState, setEmailState] = useState(true);
  const [emailSentState, setEmailSentState] = useState(false);
  // const [currentPass, setCurrentPass] = useState("");
  // const [newPass, setNewPass] = useState("");

  const passwordInputRef = useRef();
  const newPasswordInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProfileDetails = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        //axios to fetch information
        var profFetchConfig = {
          method: "get",
          url: `api/user/profile/${decoded.id}`,
        };
        axios(profFetchConfig)
          .then((response) => {
            setAccountType(response.data.profileType);
            setEmailState(response.data.emailStatus);
          })
          .catch((err) => {});
      }
    };
    fetchProfileDetails();

    // return () => {
    //   console.log("unmounted");
    // };
  }, []);

  const handleRest = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );

      const enteredCurrentPass = passwordInputRef.current.value;
      const enteredNewPass = newPasswordInputRef.current.value;

      var data = JSON.stringify({
        newPassword: enteredNewPass,
        password: enteredCurrentPass,
      });
      var config = {
        method: "post",
        url: `api/auth/changepass/${decoded.id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          passwordInputRef.current.value = "";
          newPasswordInputRef.current.value = "";
          alert("Success!");
        })
        .catch(() => {
          alert("error");
        });

      // setIsLoading(true);
    }
  };

  // validate Email
  const ValidateEmail = () => {
    try {
      setEmailSentState(true);
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/dashboard";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );

        var validateEmailConfig = {
          method: "get",
          url: `extra/send/in/blue/${decoded.id}`,
          // extra/check/email/${decoded.id}
        };
        axios(validateEmailConfig)
          .then((object) => {
            alert("email sent!");
            setEmailSentState(true);
          })
          .catch((err) => {
            alert("error");
          });
        // setIsLoading(true);
      }
    } catch (error) {
      alert("error");
    }
  };

  return (
    <div className="flex flex-col sm:flex-row sm:flex-grow w-full">
      <div className="py-4 px-4 flex justify-between sm:hidden shadow-xl text-ss-blue font-semibold">
        <button onClick={() => setSelected("Mobile Settings")}>
          <img
            className="flex transform -rotate-180"
            src="/imgs/arrow.svg"
            alt="arrow"
          />
        </button>
        <h1 className="font-semi-bold flex justify-center ml-auto mr-auto">
          Password Update
        </h1>
      </div>
      <div className="mt-10 my-20 px-4 sm:mx-20 flex flex-col w-full gap-y-10">
        <div className="hidden sm:block">
          <div className="inline-flex gap-x-3">
            <PasswordIcon fill={"ss-blue"} />
            <h1 className="font-semi-bold">Password Update</h1>
          </div>
          <hr className="mt-8 border-gray-300" />
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="inline-flex gap-x-4">
            <h4 className="text-lg font-medium">Two factor Authentication</h4>
            <div className="flex justify-end flex-grow">
              <Switch
                checked={twoFactor}
                onChange={setTwoFactor}
                className={`${
                  twoFactor ? "bg-ss-document-blue" : "bg-gray-200"
                } relative inline-flex items-center h-6 rounded-full w-11`}
              >
                <span className="sr-only">
                  Enable two factor Authentication
                </span>
                <span
                  className={`${
                    twoFactor ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full`}
                />
              </Switch>
            </div>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            consectetur pharetra vestibulum aenean arcu aenean mauris auctor
            euismod.
          </p>
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="inline-flex gap-x-4">
            <h4 className="text-lg font-medium">Authentication Email</h4>
            <div className="flex justify-end flex-grow">
              {emailState ? (
                <img width={20} src="/imgs/tick.svg" alt="tick" />
              ) : emailSentState ? (
                <p>Check Email</p>
              ) : (
                <button onClick={ValidateEmail}>Activate</button>
              )}

              {/* <Switch
                checked={authenticationEmail}
                onChange={setAuthenticationEmail}
                className={`${
                  authenticationEmail ? "bg-ss-document-blue" : "bg-gray-200"
                } relative inline-flex items-center h-6 rounded-full w-11`}
              >
                <span className="sr-only">Enable google with Gmail</span>
                <span
                  className={`${
                    authenticationEmail ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full`}
                />
              </Switch> */}
            </div>
          </div>
          <p>Google with Gmail</p>
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="inline-flex gap-x-4">
            <h4 className="text-lg font-medium">SMS Recovery</h4>
            <div className="flex justify-end flex-grow">
              <Switch
                checked={sms}
                onChange={setSms}
                className={`${
                  sms ? "bg-ss-document-blue" : "bg-gray-200"
                } relative inline-flex items-center h-6 rounded-full w-11`}
              >
                <span className="sr-only">Enable SMS Recovery</span>
                <span
                  className={`${
                    sms ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full`}
                />
              </Switch>
            </div>
          </div>
          <p>Your phone number or something</p>
        </div>
        <hr className="mt-8 border-gray-300" />
        <div className="flex flex-col gap-y-10">
          <div className="flex flex-col gap-y-4">
            <h4 className="text-lg font-medium">Password</h4>
            <p>
              Your new password has to have minimum of 8 character, one special
              character and at least one number.
            </p>
          </div>
          <form onSubmit={handleRest}>
            <div className="flex flex-col gap-y-4">
              <h4 className="text-lg font-medium">Current Password</h4>
              <input
                id="current-password"
                className="bg-gray-100 rounded-lg max-w-max pl-4 py-2 pr-20"
                placeholder="******"
                ref={passwordInputRef}
              />
            </div>
            <div className="flex flex-col gap-y-4">
              <h4 className="text-lg font-medium">New Password</h4>
              <input
                id="new-password"
                className="bg-gray-100 rounded-lg max-w-max pl-4 py-2 pr-20"
                placeholder="******"
                ref={newPasswordInputRef}
              />
            </div>
            <br></br>
            <br></br>
            <br></br>
            <button>Change Password</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PasswordUpdate;
