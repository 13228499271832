import React from "react";

const PinterestIcon = ({ fill, mobile, notifications }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 17.2525C0 24.6179 4.61649 30.9065 11.1135 33.3808C10.9554 32.0336 10.7871 29.8124 11.1494 28.2539C11.4614 26.914 13.1637 19.7168 13.1637 19.7168C13.1637 19.7168 12.6504 18.6888 12.6504 17.1663C12.6504 14.7797 14.0335 12.9969 15.7573 12.9969C17.2238 12.9969 17.9311 14.0968 17.9311 15.4151C17.9311 16.8888 16.9923 19.0914 16.5078 21.1344C16.1038 22.8438 17.3661 24.2384 19.0511 24.2384C22.1034 24.2384 24.4512 21.0193 24.4512 16.3727C24.4512 12.2594 21.4952 9.38538 17.2755 9.38538C12.3902 9.38538 9.52196 13.0501 9.52196 16.8385C9.52196 18.315 10.0899 19.8965 10.8001 20.7577C10.8606 20.8227 10.9034 20.9022 10.9243 20.9886C10.9452 21.075 10.9435 21.1653 10.9194 21.2508C10.7886 21.7943 10.4982 22.9602 10.4421 23.1989C10.3659 23.5123 10.1934 23.5799 9.86701 23.4275C7.72195 22.4297 6.382 19.2941 6.382 16.7752C6.382 11.3565 10.317 6.382 17.7284 6.382C23.6863 6.382 28.3172 10.6276 28.3172 16.3008C28.3172 22.2198 24.5863 26.9844 19.4048 26.9844C17.6637 26.9844 16.029 26.0786 15.4683 25.0104C15.4683 25.0104 14.6071 28.2913 14.3987 29.0935C13.9932 30.652 12.869 32.6245 12.1717 33.7445C13.779 34.2391 15.4841 34.5051 17.2525 34.5051C26.7802 34.5051 34.5051 26.7802 34.5051 17.2525C34.5051 7.72482 26.7802 0 17.2525 0C7.72482 0 0 7.72482 0 17.2525Z"
        fill={fill}
      />
    </svg>
  );
};

export default PinterestIcon;
