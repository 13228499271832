import PostButton from "../common/PostButton";

function MakePost({
  name,
  openModal,
  setAnalyse,
  isMobile,
  setPostMobile,
  user,
  picture,
  type,
}) {
  if (type == "Facebook" || type == "Instagram") {
    return "";
  } else {
    return (
      <div className="mx-3 sm:mx-10 sm:mt-10">
        {picture != null ? (
          <div>
            <div className="flex gap-x-4">
              <img width={80} src={picture} alt="user" />
              <input
                onClick={async () => {
                  if (isMobile) {
                    await setPostMobile(true);
                  } else {
                    await openModal();
                  }
                  setAnalyse(false);
                }}
                type="text"
                placeholder={`What's on your mind, ${name}?`}
                id="post"
                value=""
                className="mt-2 w-full sm:w-4/6 self-center rounded-lg text-gray-800 bg-gray-100 border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
              />
            </div>
            <div className="inline-flex mt-2 md:ml-20 lg:ml-0 xl:ml-20">
              <PostButton btnIcon="./imgs/video.svg" btnText="Upload Videos" />
              <PostButton btnIcon="./imgs/photo.svg" btnText="Upload Photos" />
              <PostButton
                btnIcon="./imgs/reaction.svg"
                btnText="Feeling/Activity"
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default MakePost;
