import { useState } from "react";
import { Switch } from "@headlessui/react";

function NotificationSettings({ selected, setSelected }) {
  const [message, setMessage] = useState(true);
  const [postNotifications, setPostNotifications] = useState(false);

  return (
    <div className="flex flex-col sm:flex-row sm:flex-grow w-full">
      <div className="py-4 px-4 flex justify-between sm:hidden shadow-xl text-ss-blue font-semibold">
        <button onClick={() => setSelected("Mobile Settings")}>
          <img
            className="flex transform -rotate-180"
            src="/imgs/arrow.svg"
            alt="arrow"
          />
        </button>
        <h1 className="font-semi-bold flex justify-center ml-auto mr-auto">
          Notification Settings
        </h1>
      </div>
      <div className="flex my-10 px-4 sm:mx-20 flex-col w-full gap-y-10">
        <div className="hidden sm:block">
          <h1 className="font-semi-bold">Notification Settings</h1>
          <hr className="mt-8 border-gray-300" />
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="inline-flex gap-x-4">
            <img src="/imgs/email.svg" alt="username" />
            <h4 className="text-lg font-medium">Message Notifications</h4>
            <div className="flex justify-end flex-grow">
              <Switch
                checked={message}
                onChange={setMessage}
                className={`${
                  message ? "bg-ss-document-blue" : "bg-gray-200"
                } relative inline-flex items-center h-6 rounded-full w-11`}
              >
                <span className="sr-only">
                  Enable notifications for messages
                </span>
                <span
                  className={`${
                    message ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full`}
                />
              </Switch>
            </div>
          </div>
          <div className="ml-8 flex flex-col gap-y-3">
            <div className="inline-flex gap-x-1">
              <input
                type="checkbox"
                id="all-new-messages"
                className="mr-2 self-center text-ss-document-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
              />
              <h6 className="font-medium">All new messages</h6>
            </div>
            <div className="inline-flex gap-x-1">
              <input
                type="checkbox"
                id="buzz-messages"
                className="mr-2 self-center text-ss-document-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
              />
              <h6 className="font-medium">Buzz messages</h6>
            </div>
            <div className="inline-flex gap-x-1">
              <input
                type="checkbox"
                id="mentions"
                className="mr-2 self-center text-ss-document-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
              />
              <h6 className="font-medium">Mentions</h6>
            </div>
            <div className="inline-flex gap-x-1">
              <input
                type="checkbox"
                id="private-messages"
                className="mr-2 self-center text-ss-document-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
              />
              <h6 className="font-medium">Private messages</h6>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <div className="inline-flex gap-x-4">
            <img src="/imgs/email.svg" alt="username" />
            <h4 className="text-lg font-medium">Message Notifications</h4>
            <div className="flex justify-end flex-grow">
              <Switch
                checked={postNotifications}
                onChange={setPostNotifications}
                className={`${
                  postNotifications ? "bg-ss-document-blue" : "bg-gray-200"
                } relative inline-flex items-center h-6 rounded-full w-11`}
              >
                <span className="sr-only">Enable post notification</span>
                <span
                  className={`${
                    postNotifications ? "translate-x-6" : "translate-x-1"
                  } inline-block w-4 h-4 transform transition ease-in-out duration-200 bg-white rounded-full`}
                />
              </Switch>
            </div>
          </div>
          <div className="ml-8 flex flex-col gap-y-3">
            <div className="inline-flex gap-x-1">
              <input
                type="checkbox"
                id="new-posts"
                className="mr-2 self-center text-ss-document-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
              />
              <h6 className="font-medium">Following new posts</h6>
            </div>
            <div className="inline-flex gap-x-1">
              <input
                type="checkbox"
                id="posts-tagged"
                className="mr-2 self-center text-ss-document-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
              />
              <h6 className="font-medium">Posts tagged</h6>
            </div>
            <div className="inline-flex gap-x-1">
              <input
                type="checkbox"
                id="public-posts"
                className="mr-2 self-center text-ss-document-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
              />
              <h6 className="font-medium">Public posts</h6>
            </div>
            <div className="inline-flex gap-x-1">
              <input
                type="checkbox"
                id="private-posts"
                className="mr-2 self-center text-ss-document-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
              />
              <h6 className="font-medium">Private posts</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationSettings;
