import React from "react";

const NotificationIcon = ({ fill }) => {
  return (
    <>
      <svg
        className={`fill-current text-${fill}`}
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.5714 25.7593C12.059 25.7481 12.5269 25.5648 12.8924 25.2418C13.2578 24.9188 13.4973 24.477 13.5684 23.9945H9.49707C9.57019 24.4902 9.82086 24.9423 10.2024 25.267C10.584 25.5916 11.0705 25.7665 11.5714 25.7593Z" />
        <path d="M23.0657 20.9991L22.8026 20.7669C22.056 20.1017 21.4025 19.3389 20.8598 18.499C20.267 17.34 19.9118 16.0742 19.8149 14.776V10.9523C19.8117 10.4879 19.7703 10.0245 19.691 9.56685C18.3799 9.29737 17.2022 8.58309 16.3573 7.54494C15.5124 6.5068 15.0522 5.20858 15.0546 3.87008V3.38245C14.2465 2.98475 13.3783 2.72322 12.4849 2.60843V1.63317C12.4849 1.35912 12.376 1.09629 12.1823 0.902504C11.9885 0.70872 11.7256 0.599854 11.4516 0.599854C11.1775 0.599854 10.9147 0.70872 10.7209 0.902504C10.5271 1.09629 10.4183 1.35912 10.4183 1.63317V2.64713C8.418 2.9293 6.58733 3.92561 5.26427 5.45212C3.9412 6.97863 3.21506 8.93226 3.21991 10.9523V14.776C3.12297 16.0742 2.76771 17.34 2.17499 18.499C1.6416 19.3368 0.998634 20.0995 0.263166 20.7669L0 20.9991V23.1818H23.0657V20.9991Z" />
        <path d="M20.8598 7.74018C22.9972 7.74018 24.7299 6.00748 24.7299 3.87009C24.7299 1.7327 22.9972 0 20.8598 0C18.7224 0 16.9897 1.7327 16.9897 3.87009C16.9897 6.00748 18.7224 7.74018 20.8598 7.74018Z" />
      </svg>
    </>
  );
};

export default NotificationIcon;
