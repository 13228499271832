import React from "react";

const ProfileIcon = ({ fill }) => {
  return (
    <>
      <svg
        className={`fill-current text-${fill}`}
        width="25"
        height="27"
        viewBox="0 0 25 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13 2.66667C11.1591 2.66667 9.66667 4.15905 9.66667 6C9.66667 7.84095 11.1591 9.33333 13 9.33333C14.8409 9.33333 16.3333 7.84095 16.3333 6C16.3333 4.15905 14.8409 2.66667 13 2.66667ZM7 6C7 2.68629 9.68629 0 13 0C16.3137 0 19 2.68629 19 6C19 9.31371 16.3137 12 13 12C9.68629 12 7 9.31371 7 6Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.8182 16.6C6.46192 16.6 2.36364 20.8137 2.36364 25.7C2.36364 26.418 1.83452 27 1.18182 27C0.529118 27 0 26.418 0 25.7C0 19.0982 5.42599 14 11.8182 14C12.4709 14 13 14.582 13 15.3C13 16.018 12.4709 16.6 11.8182 16.6Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.7549 15.3101C21.1339 14.8966 21.7484 14.8966 22.1275 15.3101L24.7157 18.1337C25.0948 18.5471 25.0948 19.2176 24.7157 19.6311L18.2451 26.6899C18.0631 26.8884 17.8162 27 17.5588 27H14.9706C14.4345 27 14 26.5259 14 25.9412V23.1176C14 22.8368 14.1023 22.5675 14.2843 22.3689L20.7549 15.3101ZM15.9412 23.5562V24.8824H17.1568L22.6568 18.8824L21.4412 17.5562L15.9412 23.5562Z"
        />
      </svg>
    </>
  );
};

export default ProfileIcon;
