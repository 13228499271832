import AccountIcon from "../Icons/AccountIcon";
import LogoutIcon from "../Icons/LogoutIcon";
import NotificationIcon from "../Icons/NotificationIcon";
import PasswordIcon from "../Icons/PasswordIcon";
import ProfileIcon from "../Icons/ProfileIcon";

function MobileSettings({ selected, setSelected }) {
  return (
    <>
      <div className="pb-48 flex flex-col w-full gap-y-4">
        <div className="py-4 px-4 inline-flex shadow-lg text-ss-blue font-semibold">
          <h1 className="font-semi-bold">Settings</h1>
          <img
            className="flex justify-end ml-auto"
            src="/imgs/search.svg"
            alt="search"
          />
        </div>
        <div className="flex mx-4 gap-x-2">
          <img src="./imgs/person.svg" alt="user" />
          <div className="flex flex-col self-center">
            <h1 className="font-semibold">Chris Do</h1>
            <h3>chrisdo@example.org</h3>
          </div>
        </div>
        <hr className="border-gray-300 mx-4" />

        <div className="flex flex-col gap-y-4 mt-4 mx-4">
          <div
            onClick={() => setSelected("Profile Settings")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <ProfileIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Profile Settings</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
          <div
            onClick={() => setSelected("Account Settings")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <AccountIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Account Settings</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
          <div
            onClick={() => setSelected("Notification Settings")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <NotificationIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Notification Settings</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
          <div
            onClick={() => setSelected("Password Update")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <PasswordIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Password Update</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
          <div
            onClick={() => setSelected("Profile Settings")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <LogoutIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Logout</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileSettings;
