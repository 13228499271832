import { useHistory } from "react-router";

function SocialPillMobile({
  icon,
  text,
  setSelected,
  selected,
  social,
  notifications,
}) {
  const history = useHistory();
  return (
    <button
      id={text}
      onClick={async (e) => {
        await setSelected(e.currentTarget.id);
        await history.push("/dashboard");
      }}
    >
      <div
        className={`text-white p-3 w-full hover:bg-gray-200 bg-ss-black
          ${
            selected === text
              ? `bg-ss-blue text-white hover:bg-ss-blue`
              : `text-gray-400`
          }`}
      >
        {icon}
        {/* {social && notifications && (
          <div
            className={`rounded-full items-center justify-center p-1 h-6 w-6 text-xs ml-3 text-white ${
              selected ? `bg-red-500` : `bg-red-300`
            }`}
          >
            {notifications}
          </div>
        )} */}
      </div>
    </button>
  );
}

export default SocialPillMobile;
