import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Routing
import PrivateRoute from "./components/routing/PrivateRoute";

// Screens
import Dashboard from "./components/DashBoard/Dashboard";
import Registration from "./components/SignUp/Registration";
import Login from "./components/Login/Login";
import MyAccount from "./components/MyAccount/MyAccount";
import ValidateView from "./components/Validation/ValidationView";

const App = () => {
  return (
    <Router>
      <div className="app">
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/myaccount" component={MyAccount} />
          <Route exact path="/validate/email/:id" component={ValidateView} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/registration" component={Registration} />

          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
