import { useEffect, useState } from "react";
import Analyse from "../AnalyseMenu/Analyse";
import AnalyseMobile from "../AnalyseMenu/AnalyseMobile";
import MakePost from "./MakePost";
import NavBar from "../common/NavBar";
import ViewPost from "./ViewPost";
import ViewDraft from "./ViewDraft";
import SideBar from "../common/SideBar";
import axios from "axios";
import jwt from "jsonwebtoken";
import CreatePostMobileModal from "../PostModal/CreatePostMobileModal";
import CreatePostModal from "../PostModal/CreatePostModal";

function Dashboard() {
  const [isOpen, setIsOpen] = useState(false);
  const [newPost, setNewPost] = useState("");
  const [searchTerm, setSearchTerm] = useState([""]);
  const [analyseIssues, setAnalyseIssues] = useState(false);
  const [postMobile, setPostMobile] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [highlightColor, setHighlightColor] = useState(
    "rgba(249, 166, 17, 0.5)"
  );

  function openModal() {
    setIsOpen(true);
  }
  const [keywords, setKeywords] = useState([new RegExp()]);

  // toggle for analyse button
  const [analyse, setAnalyse] = useState(false);
  // to keep track of which post is being curently analysed
  const [currentIndex, setCurrentIndex] = useState(0);

  //  stores all the posts fetched from API...dummy array as of now

  // const [posts, setPosts] = useState([
  //   ["Today is super good, sunny and windy. ", [], []],
  //   [
  //     "Sally's email is bob@example.org. My goodness All wth. How annoying! Bob is a good Allguy, I love him. It's not fair for Sally to steal his email name-right! I am an abc@example.org",
  //     ["Bob", "Sally"],
  //     ["bob@example.org", "All", "abc@example.org", "steal", "name-right"],
  //   ],
  //   [
  //     "What is the difference between art and design? - www.google.com This student lead Q&A covers art vs. design, how to provide value to clients, what to charge for your work, and why you shouldnt worry about being original.",
  //     ["art", "the"],
  //     ["www.google.com"],
  //   ],
  //   [
  //     "What is the difference between art and design? - www.google.com This podcast episode comes from a livestream we hosted via Youtube in early 2020. in it, Chris Do fields questions from a group of design majors visiting from Loyola Marymount Univerisity. The Q&A covers topics like the difference between art and design, demonstrating your value to clients, what to charge for your work, and why there is truly nohing new under the sun.",
  //     ["art", "the"],
  //     ["Youtube in early 2020"],
  //   ],
  //   [
  //     "What is design thinking? How can you better use it to solve problems throughout your life? How did the Washington Monument get cleaner by using this topic? Rather than delivery the same-old-same-solutions to your client’s biggest challenges, The Design Thinking Framework helps you to stand out and provide fresh ways of tackling problems. All with the goal of bringing innovative solution to your clients’ biggest problems. Download the Problem Tree Analysis Template: https//google.com",
  //     ["design", "Washington"],
  //     ["same-old-same-solutions"],
  //   ],
  // ]);

  // Testing fetch posts from API
  // const [postsUsers, setPostsUsers] = useState("");

  const [posts, setPosts] = useState([["", [], []]]);
  const [postsUrl, setPostsUrl] = useState("");
  const [postsDate, setPostsDate] = useState("");
  const [postsType, setPostsType] = useState("");
  const [postsProfilePicUrl, setPostsProfilePicUrl] = useState("");
  const [postsUserName, setPostsUserName] = useState("");
  const [postsPlatform, setPostsPlatform] = useState("");
  const [postsId, setPostsId] = useState("");
  const [postUrlScore, setPostUrlScore] = useState();

  // draft
  const [draftPostsId, setdraftPostsId] = useState("");
  const [draftPost, setdraftPost] = useState([["", [], []]]);
  const [draftDate, setdraftDate] = useState("");
  const [draftName, setdraftName] = useState("");

  const [direc, setDirec] = useState(1);

  // for deleting an issue from the smaller card
  const deleteEntry = async (word) => {
    const filteredItems = await posts[currentIndex][1].filter(
      (item) => item !== word
    );
    const updatedArray = [...posts];
    updatedArray[currentIndex][1] = filteredItems;
    await setPosts(updatedArray);
    setKeywords([new RegExp()]);
    setHighlightColor("rgba(249, 166, 17, 0.5)");
  };

  // for deleting link cards
  const deleteLink = async (word) => {
    // console.log(posts[currentIndex][11]);

    posts[currentIndex][11].map((value, index) => {
      if (value.dataNeeded.key == word) {
        posts[currentIndex][11].splice(index, 1);
      }
    });
    // const filteredItems = await posts[currentIndex][11].filter(
    //   (item) => item !== word
    // );
    // console.log(filteredItems);
    // const updatedArray = [...posts];
    // updatedArray[currentIndex][11] = filteredItems;
    await setPosts(posts);
    setKeywords([new RegExp()]);
    setHighlightColor("rgba(249, 166, 17, 0.5)");
  };

  // for deleting an issue from the privacy cards
  const deleteEmail = async (word) => {
    const filteredItems = await posts[currentIndex][2].filter(
      (item) => item !== word
    );
    const updatedArray = [...posts];
    updatedArray[currentIndex][2] = filteredItems;
    await setPosts(updatedArray);
    setKeywords([new RegExp()]);
    setHighlightColor("rgba(249, 166, 17, 0.5)");
  };
  /// twitter post

  const getOverallPosts = async () => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.gref = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );

        var config = {
          method: "get",
          url: `overall/get/feed/${decoded.id}`,
        };
        axios(config)
          .then((res) => {
            let tempArray = [];
            let urlList = [];
            let dateList = [];
            let typeList = [];
            let profilePicList = [];
            let userName = [];
            let postPlatform = [];
            let postId = [];
            let rawData = res.data;
            let reference_post = [];
            let urlScore = [];
            for (let i = 0; i < res.data.length; i++) {
              // tempArray.push([res.data[i][0].text]);
              urlList.push(res.data[i][3]);
              dateList.push(res.data[i][4]);
              typeList.push(res.data[i][5]);
              profilePicList.push(res.data[i][6]);
              userName.push(res.data[i][7]);
              postPlatform.push(res.data[i][12]);
              postId.push(res.data[i][10]);
              urlScore.push(res.data[i][11]);

              // console.log("list", res.data[i][3]);

              // 10
            }
            setPostsUrl(urlList);
            setPostsDate(dateList);
            setPostsType(typeList);
            setPostsProfilePicUrl(profilePicList);
            setPostsUserName(userName);
            setPostsPlatform(postPlatform);
            setPostsId(postId);
            // console.log("postsUser", postsUser);
            setPosts(rawData);
            setPostUrlScore(urlScore);
          })
          .catch((err) => {
            alert("error");
          });
      }
    } catch (error) {
      alert("error");
    }
  };

  const getTwitterPosts = async () => {
    try {
      setCurrentIndex(0);
      setPosts([["", [], []]]);
      setAnalyse(false);

      // setPosts();
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        console.log(decoded);
        // fetching from twitter api
        const res = await axios.get(
          `twitter/feed/${decoded.id}/0`
          // `twitter/timeline/${currentUser._id}`
        );
        let tempArray = [];
        let urlList = [];
        let dateList = [];
        let typeList = [];
        let profilePicList = [];
        let userName = [];
        let postPlatform = [];
        let postId = [];
        let rawData = res.data;
        let reference_post = [];
        let urlScore = [];
        // console.log(res.data);
        for (let i = 0; i < res.data.length; i++) {
          // tempArray.push([res.data[i][0].text]);
          urlList.push(res.data[i][3]);
          dateList.push(res.data[i][4]);
          typeList.push(res.data[i][5]);
          profilePicList.push(res.data[i][6]);
          userName.push(res.data[i][7]);
          postPlatform.push(res.data[i][12]);
          postId.push(res.data[i][10]);
          urlScore.push(res.data[i][11]);

          // console.log("list", res.data[i][3]);

          // 10
        }
        setPostsUrl(urlList);
        setPostsDate(dateList);
        setPostsType(typeList);
        setPostsProfilePicUrl(profilePicList);
        setPostsUserName(userName);
        setPostsPlatform(postPlatform);
        setPostsId(postId);
        // console.log("postsUser", postsUser);
        setPosts(rawData);
        setPostUrlScore(urlScore);
        // console.log('tempArray', tempArray);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const fN = async () => {
  //   await setDirec(1);
  //   console.log("next", direc);
  //   getFacebookPosts();
  // };
  // const fB = async () => {
  //   await setDirec(0);
  //   getFacebookPosts();
  //   console.log("back", direc);
  // };

  // fetching from facebook
  const getFacebookPosts = async () => {
    try {
      setCurrentIndex(0);
      setPosts([["", [], []]]);
      setAnalyse(false);

      // setPosts();
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        var config = {
          method: "get",
          url: `facebook/feed/${decoded.id}/0`,
        };
        axios(config)
          .then((res) => {
            let tempArray = [];
            let urlList = [];
            let dateList = [];
            let typeList = [];
            let profilePicList = [];
            let userName = [];
            let postPlatform = [];
            let postId = [];
            let rawData = res.data;
            let reference_post = [];
            let urlScore = [];
            // console.log(res.data);
            for (let i = 0; i < res.data.length; i++) {
              // tempArray.push([res.data[i][0].text]);
              urlList.push(res.data[i][3]);
              dateList.push(res.data[i][4]);
              typeList.push(res.data[i][5]);
              profilePicList.push(res.data[i][6]);
              userName.push(res.data[i][7]);
              postPlatform.push(res.data[i][12]);
              postId.push(res.data[i][10]);
              urlScore.push(res.data[i][11]);

              // console.log("list", res.data[i][3]);

              // 10
            }

            setPostsUrl(urlList);
            setPostsDate(dateList);
            setPostsType(typeList);
            setPostsProfilePicUrl(profilePicList);
            setPostsUserName(userName);
            setPostsPlatform(postPlatform);
            setPostsId(postId);
            // console.log("postsUser", postsUser);
            setPosts(rawData);
            setPostUrlScore(urlScore);
            // console.log('tempArray', tempArray);
          })
          .catch((err) => {});
        // fetching from twitter api
        // const res = await axios.get(
        //   `facebook/feed/${decoded.id}/${direc}`
        // );
      }
    } catch (err) {
      console.log(err);
    }
  };
  // get instagram post
  const getInstagramPosts = async () => {
    try {
      setCurrentIndex(0);
      setPosts([["", [], []]]);
      setAnalyse(false);

      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        // setPosts();

        // fetching from twitter api
        const res = await axios.get(`instagram/feed/${decoded.id}/0`);
        let tempArray = [];
        let urlList = [];
        let dateList = [];
        let typeList = [];
        let profilePicList = [];
        let userName = [];
        let postPlatform = [];
        let postId = [];
        let rawData = res.data;
        let reference_post = [];
        let urlScore = [];
        // console.log(res.data);
        for (let i = 0; i < res.data.length; i++) {
          // tempArray.push([res.data[i][0].text]);
          urlList.push(res.data[i][3]);
          dateList.push(res.data[i][4]);
          typeList.push(res.data[i][5]);
          profilePicList.push(res.data[i][6]);
          userName.push(res.data[i][7]);
          postPlatform.push(res.data[i][12]);
          postId.push(res.data[i][10]);
          urlScore.push(res.data[i][11]);

          // console.log("list", res.data[i][3]);

          // 10
        }
        // const draftRes = await axios.get(
        //   `twitter/draft/fetch/post/${decoded.id}`
        //   // `twitter/timeline/${currentUser._id}`
        // );
        // // console.log(draftRes.data);
        // let draftRawData = draftRes.data;
        // let draftDate = [];
        // let draftName = [];
        // let draftPostId = [];
        // for (let m = 0; m < draftRes.data.length; m++) {
        //   draftDate.push(draftRes.data[m][4]);
        //   draftName.push(draftRes.data[m][6]);
        //   draftPostId.push(draftRes.data[m][7]);
        // }

        // setdraftPost(draftRawData);
        // setdraftPostsId(draftPostId);
        // setdraftDate(draftDate);
        // setdraftName(draftName);
        // console.log(typeList[0][0]);
        // console.log("length", res.data.length);
        // console.log("list", urlList);

        // setPostsUsers(postsUser);
        setPostsUrl(urlList);
        setPostsDate(dateList);
        setPostsType(typeList);
        setPostsProfilePicUrl(profilePicList);
        setPostsUserName(userName);
        setPostsPlatform(postPlatform);
        setPostsId(postId);

        setPosts(rawData);

        setPostUrlScore(urlScore);
        // console.log('tempArray', tempArray);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // fetched draft post for now only twitter
  const getDraftPost = () => {};

  useEffect(() => {
    const getToken = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        try {
          const decoded = jwt.verify(
            token,
            "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
          );
          // console.log('Decoded: ', decoded);
          const res = await axios.get(`api/user/${decoded.id}`);
          let currentUser = res.data;

          setCurrentUser(currentUser);
          // console.log('currentUser', currentUser);
        } catch (err) {
          console.log(err);
          localStorage.removeItem("authToken");
          window.location.href = "/";
        }
      }
    };

    // Testing fetch posts from API
    // const getPosts = async () => {
    //   try {
    //     const res = await axios.get(
    //       `api/post/user/${currentUser._id}`
    //     );
    //     let tempArray = [];
    //     for (let i = 0; i < res.data.length; i++) {
    //       tempArray.push([res.data[i].desc]);
    //     }
    //     let postsUser = res.data;
    //     setPostsUsers(postsUser);
    //     console.log("postsUser", postsUser);
    //     setPosts(tempArray);
    //     // console.log('tempArray', tempArray);
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    // for twitter post

    getToken();
    getOverallPosts();
  }, [currentUser._id]);

  //
  // managing the conditional rendering after checking if any issues exist...based on the 2 arrays
  // useEffect(() => {
  // 	const hasIssues =
  // 		posts[currentIndex][1].length + posts[currentIndex][2].length > 0;
  // 	if (hasIssues) {
  // 		setAanalyseIssues(true);
  // 	} else {
  // 		setAanalyseIssues(false);
  // 	}
  // }, [currentIndex, posts]);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  // if (postsUsers === '') return null;

  return (
    <>
      <NavBar />

      <div className="flex pb-20 sm:pb-0">
        <SideBar
          getTwitterPosts={getTwitterPosts}
          getFacebookPosts={getFacebookPosts}
          getInstagramPosts={getInstagramPosts}
          getOverallPosts={getOverallPosts}
        />

        <div className="flex flex-col flex-grow">
          <div className="">
            <MakePost
              name={currentUser.name}
              isMobile={isMobile}
              setPostMobile={setPostMobile}
              openModal={openModal}
              setAnalyse={setAnalyse}
              user={postsUserName[0]}
              picture={postsProfilePicUrl[0]}
              type={postsPlatform[0]}
            />
          </div>

          {isMobile & postMobile ? (
            <CreatePostMobileModal
              postMobile={postMobile}
              setPostMobile={setPostMobile}
              newPost={newPost}
              setNewPost={setNewPost}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              allPosts={posts}
              setPosts={setPosts}
              setAnalyse={setAnalyse}
              analyse={analyse}
              setCurrentIndex={setCurrentIndex}
              setKeywords={setKeywords}
              keywords={keywords}
              posts={posts}
              currentIndex={currentIndex}
              analyseIssues={analyseIssues}
              currentUser={currentUser}
              isOpen={isOpen}
              highlightColor={highlightColor}
              setHighlightColor={setHighlightColor}
            />
          ) : (
            <CreatePostModal
              currentUser={currentUser}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              newPost={newPost}
              setNewPost={setNewPost}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              allPosts={posts}
              setPosts={setPosts}
              setAnalyse={setAnalyse}
              analyse={analyse}
              setCurrentIndex={setCurrentIndex}
              setKeywords={setKeywords}
              keywords={keywords}
              posts={posts}
              currentIndex={currentIndex}
              analyseIssues={analyseIssues}
              highlightColor={highlightColor}
              setHighlightColor={setHighlightColor}
              user={postsUserName[0]}
              picture={postsProfilePicUrl[0]}
              type={postsPlatform[0]}
            />
          )}
          {/* {
            <div className="flex flex-row gap-x-20 px-10 py-1">
              <div onClick={fB}>Back</div>
              <div className="flex-grow"></div>
              <div onClick={fN}>Next</div>
            </div>
          } */}

          {posts.map((post, index) => {
            return (
              <ViewPost
                index={index}
                active={currentIndex === index}
                key={index}
                keywords={keywords}
                textToHighlight={post[0]}
                analyse={analyse}
                setAnalyse={setAnalyse}
                setCurrentIndex={setCurrentIndex}
                setKeywords={setKeywords}
                currentIndex={currentIndex}
                posts={posts}
                // postsUsers={postsUsers}
                highlightColor={highlightColor}
                setHighlightColor={setHighlightColor}
                imageUrl={postsUrl[index]}
                date={postsDate[index]}
                type={postsType[index]}
                profilePicUrl={postsProfilePicUrl[index]}
                username={postsUserName[index]}
                postType={postsPlatform[index]}
                postId={postsId[index]}
                allPosts={posts}
                setPosts={setPosts}
              />
            );
          })}

          {/* 
          {draftPost.map((draft, draftIndex) => {
            return (
              <ViewDraft
                index={draftIndex}
                active={currentIndex === draftIndex}
                key={draftIndex}
                keywords={keywords}
                textToHighlight={draft[0]}
                analyse={analyse}
                setAnalyse={setAnalyse}
                setCurrentIndex={setCurrentIndex}
                setKeywords={setKeywords}
                currentIndex={currentIndex}
                posts={draftPost}
                // postsUsers={postsUsers}
                highlightColor={highlightColor}
                setHighlightColor={setHighlightColor}
                // imageUrl={postsUrl[index]}
                date={draftDate[draftIndex]}
                // type={postsType[index]}
                // profilePicUrl={postsProfilePicUrl[index]}
                username={draftName[draftIndex]}
                // postType={postsPlatform[index]}
                postId={draftPostsId[draftIndex]}
              />
            );
          })} */}
        </div>

        {isMobile ? (
          <AnalyseMobile
            analyse={analyse}
            setAnalyse={setAnalyse}
            posts={posts}
            keywords={keywords}
            setKeywords={setKeywords}
            deleteEntry={deleteEntry}
            currentIndex={currentIndex}
            deleteEmail={deleteEmail}
            analyseIssues={analyseIssues}
            setAnalyseIssues={setAnalyseIssues}
            setHighlightColor={setHighlightColor}
          />
        ) : (
          <Analyse
            analyse={analyse}
            posts={posts}
            keywords={keywords}
            setKeywords={setKeywords}
            deleteEntry={deleteEntry}
            linkDelete={deleteLink}
            currentIndex={currentIndex}
            deleteEmail={deleteEmail}
            analyseIssues={analyseIssues}
            setAnalyseIssues={setAnalyseIssues}
            setHighlightColor={setHighlightColor}
            score={postUrlScore}
          />
        )}
      </div>
    </>
  );
}

export default Dashboard;
