import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Button from "../common/Button.js";
import GoogleButton from "../common/GoogleButton.js";

const schema = yup.object().shape({
  name: yup.string().required("Name is required."),
  email: yup.string().email().required("Email is required."),
  password: yup
    .string()
    .min(5, "Password must be at least 5 characters.")
    .max(20, "Password cannot be more than 20 characters.")
    .required("Password is required."),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
  terms: yup.boolean().oneOf([true], "Terms and Condition must be accepted!"),
});

function Registration() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  // console.log('Errors: ', errors);

  const onSubmit = async (userDetails) => {
    console.log(userDetails);
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axios.post(
        "api/auth/register",
        {
          name: userDetails.name,
          email: userDetails.email,
          password: userDetails.password,
        },
        config
      );
      localStorage.setItem("authToken", data.token);
      alert("User Registered Successfully");
      window.location.href = "/login";
    } catch (error) {
      setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <>
      <section className="bg-register w-full h-full bg-no-repeat bg-cover font-Poppins">
        <div className="grid grid-flow-col">
          <div className="text-gray-700 py-44 px-32 sm:flex flex-col gap-y-2 hidden">
            <h1 className="font-bold text-3xl">Welcome Back,</h1>
            <h1>start planning today.</h1>
          </div>
          <div className="py-20 px-4 grid justify-center text-center">
            <div className="py-10 rounded-lg grid gap-y-4 bg-white px-4 md:px-20">
              <h2 className="font-semi-bold text-2xl text-gray-600">
                Create Account
              </h2>
              <GoogleButton text="Sign Up with Google" />
              <div className="divider mt-6 text-gray-500">
                <p className="uppercase text-xs">
                  <span>or sign up with email</span>
                </p>
              </div>
              <form
                className="justify-start text-left flex flex-col gap-y-8 text-base text-gray-500 font-light"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="block">
                  <span>Full Name</span>
                  <input
                    {...register("name")}
                    type="text"
                    id="name"
                    className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                  />
                  <p>{errors.name?.message}</p>
                </div>
                <div className="block">
                  <span>Email</span>
                  <input
                    {...register("email")}
                    type="email"
                    id="email"
                    className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                  />
                  <p>{errors.email?.message}</p>
                  {/* {errors.email ? alert(errors.email.message) : null} */}
                </div>
                <div className="block">
                  <span>Password</span>
                  <div className="relative">
                    <input
                      {...register("password")}
                      type={showPassword ? `text` : `password`}
                      id="password"
                      className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                    />
                    <p>{errors.password?.message}</p>
                    {/* {console.log(errors.password?.message)} */}
                    <svg
                      className="absolute top-1/2 transform -translate-y-1 right-3 cursor-pointer"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <path
                        d="M2.29243 6.35548C2.61993 6.1036 3.08925 6.16411 3.34223 6.49025L3.34478 6.49345C3.34816 6.49767 3.35472 6.50577 3.36448 6.51743C3.38399 6.54077 3.41624 6.57832 3.46135 6.6276C3.55162 6.72622 3.69298 6.87137 3.88633 7.04328C4.27335 7.38738 4.86585 7.83622 5.67161 8.23499C6.72929 8.75843 8.16282 9.19999 9.99966 9.19999C11.8365 9.19999 13.27 8.75843 14.3277 8.23499C15.1335 7.83622 15.726 7.38738 16.113 7.04328C16.3063 6.87137 16.4477 6.72622 16.538 6.6276C16.5831 6.57832 16.6153 6.54077 16.6349 6.51743C16.6446 6.50577 16.6512 6.49767 16.6545 6.49345L16.6568 6.49064C16.9098 6.16448 17.3794 6.1036 17.7069 6.35548C18.0352 6.608 18.0967 7.07888 17.8442 7.40722L17.8429 7.40887L17.8413 7.41093L17.8372 7.41622L17.8251 7.43146C17.8153 7.44364 17.8021 7.45983 17.7855 7.47969C17.7523 7.5194 17.7053 7.57385 17.6444 7.64039C17.5227 7.7734 17.3449 7.95515 17.1097 8.16427C16.758 8.47695 16.2764 8.8522 15.6607 9.21711L16.8819 11.6089C17.0703 11.9779 16.9239 12.4296 16.555 12.618C16.1861 12.8063 15.7343 12.6599 15.546 12.291L14.317 9.88404C13.3369 10.2842 12.1493 10.5946 10.7497 10.6779V12.95C10.7497 13.3642 10.4139 13.7 9.99966 13.7C9.58545 13.7 9.24966 13.3642 9.24966 12.95V10.6779C7.85001 10.5946 6.66248 10.2842 5.68229 9.88404L4.45335 12.291C4.265 12.6599 3.81324 12.8063 3.44433 12.618C3.07542 12.4296 2.92905 11.9779 3.11741 11.6089L4.33861 9.21711C3.72289 8.8522 3.24133 8.47695 2.88965 8.16427C2.65445 7.95515 2.47664 7.7734 2.35489 7.64039C2.29398 7.57385 2.24701 7.5194 2.2138 7.47969C2.19719 7.45983 2.18402 7.44364 2.17425 7.43146L2.16216 7.41622L2.15802 7.41093L2.15643 7.40887L2.15575 7.408C1.90323 7.07966 1.96409 6.608 2.29243 6.35548Z"
                        fill="#3F434A"
                      />
                    </svg>
                  </div>
                </div>
                <div className="block">
                  <span>Confirm Password</span>
                  <div className="relative">
                    <input
                      {...register("confirmPassword")}
                      type={showPassword ? `text` : `password`}
                      id="confirmPassword"
                      className="mt-2 w-full rounded-xl border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50"
                    />
                    <p>
                      {errors.confirmPassword
                        ? "Password Does Not Match!"
                        : null}
                    </p>
                    {/* {console.log(
											errors.confirmPassword && 'Password Does Not Match!'
										)} */}
                    <svg
                      className="absolute top-1/2 transform -translate-y-1 right-3 cursor-pointer"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      <path
                        d="M2.29243 6.35548C2.61993 6.1036 3.08925 6.16411 3.34223 6.49025L3.34478 6.49345C3.34816 6.49767 3.35472 6.50577 3.36448 6.51743C3.38399 6.54077 3.41624 6.57832 3.46135 6.6276C3.55162 6.72622 3.69298 6.87137 3.88633 7.04328C4.27335 7.38738 4.86585 7.83622 5.67161 8.23499C6.72929 8.75843 8.16282 9.19999 9.99966 9.19999C11.8365 9.19999 13.27 8.75843 14.3277 8.23499C15.1335 7.83622 15.726 7.38738 16.113 7.04328C16.3063 6.87137 16.4477 6.72622 16.538 6.6276C16.5831 6.57832 16.6153 6.54077 16.6349 6.51743C16.6446 6.50577 16.6512 6.49767 16.6545 6.49345L16.6568 6.49064C16.9098 6.16448 17.3794 6.1036 17.7069 6.35548C18.0352 6.608 18.0967 7.07888 17.8442 7.40722L17.8429 7.40887L17.8413 7.41093L17.8372 7.41622L17.8251 7.43146C17.8153 7.44364 17.8021 7.45983 17.7855 7.47969C17.7523 7.5194 17.7053 7.57385 17.6444 7.64039C17.5227 7.7734 17.3449 7.95515 17.1097 8.16427C16.758 8.47695 16.2764 8.8522 15.6607 9.21711L16.8819 11.6089C17.0703 11.9779 16.9239 12.4296 16.555 12.618C16.1861 12.8063 15.7343 12.6599 15.546 12.291L14.317 9.88404C13.3369 10.2842 12.1493 10.5946 10.7497 10.6779V12.95C10.7497 13.3642 10.4139 13.7 9.99966 13.7C9.58545 13.7 9.24966 13.3642 9.24966 12.95V10.6779C7.85001 10.5946 6.66248 10.2842 5.68229 9.88404L4.45335 12.291C4.265 12.6599 3.81324 12.8063 3.44433 12.618C3.07542 12.4296 2.92905 11.9779 3.11741 11.6089L4.33861 9.21711C3.72289 8.8522 3.24133 8.47695 2.88965 8.16427C2.65445 7.95515 2.47664 7.7734 2.35489 7.64039C2.29398 7.57385 2.24701 7.5194 2.2138 7.47969C2.19719 7.45983 2.18402 7.44364 2.17425 7.43146L2.16216 7.41622L2.15802 7.41093L2.15643 7.40887L2.15575 7.408C1.90323 7.07966 1.96409 6.608 2.29243 6.35548Z"
                        fill="#3F434A"
                      />
                    </svg>
                  </div>
                </div>
                <div className="inline-flex items-center text-sm">
                  <input
                    {...register("terms", {
                      value: false,
                    })}
                    type="checkbox"
                    id="terms"
                    className="mr-2 text-ss-blue rounded-sm border-gray-300 shadow-sm focus:border-ss-blue focus:ring-0"
                  />
                  <p>
                    I accept the
                    <a className="text-ss-blue ml-1" href="#">
                      Terms and Conditions
                    </a>
                    <br />
                    <p>{errors.terms?.message}</p>
                  </p>
                </div>
                <Button type="submit" text="Create Account" />
              </form>
              <div className="text-sm mt-16 text-gray-600 font-light">
                <p>
                  Already have an account?
                  <Link to="/login" className="text-ss-blue ml-1">
                    Login
                  </Link>
                </p>
              </div>
            </div>
            <p className="text-white mt-16">
              @2021 Allmeans Pte Ltd rights reserved
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Registration;
