import { useState, Fragment, useEffect } from "react";
import jwt from "jsonwebtoken";
import axios from "axios";

import AccountIcon from "../Icons/AccountIcon";
import FacebookIcon from "../Icons/FacebookIcon";
import InstagramIcon from "../Icons/InstagramIcon";
import LinkedInIcon from "../Icons/LinkedInIcon";
import TwitterIcon from "../Icons/TwitterIcon";
import PinterestIcon from "../Icons/PinterestIcon";

function AccountSettings({ selected, setSelected }) {
  const [email, setemail] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState([]);
  const [instapos, setInstaPos] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [pinterest, setPinterest] = useState("");
  // fetch
  useEffect(() => {
    fetchProfileDetails();

    // return () => {
    //   console.log("unmounted");
    // };
  }, []);
  const fetchProfileDetails = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      //axios to fetch information
      var profFetchConfig = {
        method: "get",
        url: `api/user/profile/${decoded.id}`,
      };
      axios(profFetchConfig)
        .then((response) => {
          setemail(response.data.email);
          setFacebook(response.data.facebookStatus);
          setInstagram(response.data.instagramStatus);
          setInstaPos(response.data.instagramAccountPos);
          setTwitter(response.data.twitterStatus);
        })
        .catch((err) => {});
    }
  };
  const _twitterOauth = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      try {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        // console.log('Decoded: ', decoded);

        window.open(`twitter/setting/${decoded.id}`, "_self");

        // console.log('currentUser', currentUser);
      } catch (err) {
        console.log(err);
        localStorage.removeItem("authToken");
        window.location.href = "/";
      }
    }

    //open twitter auth page on current page
  };

  const _facebookOauth = async () => {
    console.log("clicked");
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      try {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        // console.log('Decoded: ', decoded);

        window.open(`facebook/setting/${decoded.id}`, "_self");

        // console.log('currentUser', currentUser);
      } catch (err) {
        console.log(err);
        localStorage.removeItem("authToken");
        window.location.href = "/";
      }
    }

    //open twitter auth page on current page
  };

  // instagram oauth
  const _instaOauth = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      try {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );

        // console.log('Decoded: ', decoded);
        const clearLogout = async () => {
          let loggingOut = window.open(
            "http://instagram.com/accounts/logout/",
            "width=0, height=0"
          );
          console.log("open");
          return loggingOut;
        };

        // const closing = async () => {
        clearLogout().then((value) => {
          // console.log(value);
          // value.close();
          setTimeout(() => {
            value.close();
          }, 1000);

          setTimeout(() => {
            window.open(`instagram/authorize/${decoded.id}`, "_self");
          }, 1500);
        });
        // };

        // console.log('currentUser', currentUser);
      } catch (err) {
        console.log(err);
        localStorage.removeItem("authToken");
        window.location.href = "/";
      }
    }
  };

  const _instaAccountSwitch = (pos) => {
    return () => {
      console.log(instapos);
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        try {
          const decoded = jwt.verify(
            token,
            "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
          );
          // console.log('Decoded: ', decoded);

          var instaSwitchConfig = {
            method: "get",
            url: `instagram/swtich/user/${decoded.id}/${pos}`,
          };
          axios(instaSwitchConfig)
            .then((response) => {
              setInstaPos(response.data.position);
            })
            .catch(() => {
              alert("error in switching account");
            });

          // console.log('currentUser', currentUser);
        } catch (err) {
          console.log(err);
          localStorage.removeItem("authToken");
          window.location.href = "/";
        }
      }
    };
  };

  const removeLoggedIn = (value) => {
    return () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        try {
          const decoded = jwt.verify(
            token,
            "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
          );
          var config = {
            method: "get",
            url: `api/user/remove/social/${decoded.id}/${value}`,
          };
          axios(config)
            .then((response) => {
              fetchProfileDetails();
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (err) {
          console.log(err);
          localStorage.removeItem("authToken");
          window.location.href = "/";
        }
      }
    };
  };

  // remove current logged in user
  const yeetUser = () => {
    console.log("yeet");
    // var config = {
    //   method: "get",
    //   url: "https://www.facebook.com/logout.php",
    // };
    // axios(config)
    //   .then((response) => {
    //     console.log("done");
    //   })
    //   .catch((error) => {
    //     console.log(error, "fked");
    //   });
  };

  // decl

  return (
    <div className="flex flex-col sm:flex-row sm:flex-grow w-full">
      <div className="py-4 px-4 flex justify-between sm:hidden shadow-xl text-ss-blue font-semibold">
        <button onClick={() => setSelected("Mobile Settings")}>
          <img
            className="flex transform -rotate-180"
            src="/imgs/arrow.svg"
            alt="arrow"
          />
        </button>
        <h1 className="font-semi-bold flex justify-center ml-auto mr-auto">
          Account Settings
        </h1>
      </div>
      <div className="mb-20 sm:mb-0 mt-10 px-4 sm:mx-20 flex flex-col w-full gap-y-10">
        <div className="hidden sm:block">
          <div className="inline-flex gap-x-3">
            <div className="self-center">
              <AccountIcon fill={"ss-blue"} />
            </div>
            <h1 className="font-semi-bold">Account Settings</h1>
          </div>
          <hr className="mt-8 border-gray-300" />
        </div>
        <div className="flex flex-col gap-y-4">
          <h4 className="text-lg font-medium">Share Aware Account</h4>

          <div className="bg-gray-100 rounded-lg min-w-max pl-4 py-4 inline-flex gap-x-4">
            <div className="flex bg-ss-blue h-8 w-8 items-center justify-center rounded-full text-white">
              <p>SS</p>
            </div>
            <p className="self-center">{email}</p>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <h4 className="text-lg font-medium">Social Media Accounts</h4>
          <button onClick={yeetUser}> Test! </button>
          <div
            className="bg-gray-100 rounded-lg min-w-max pl-4 py-4 inline-flex gap-x-4"
            onClick={_facebookOauth}
          >
            <FacebookIcon fill={"#4D669B"} />

            <h4 className="self-center">Facebook</h4>
            {facebook ? <img width={20} src="/imgs/tick.svg" alt="tick" /> : ""}
          </div>
          <div className="flex-grow"></div>
          <p onClick={removeLoggedIn("facebook")}>Remove</p>
          {/* <a href="http://instagram.com/accounts/logout/">
            <img src="" width="0" height="0" alt="Add Instagram account" />
          </a> */}
          <button onClick={_instaOauth}>Add Instagram account</button>
          {instagram.length < 0 ? (
            <div className="bg-gray-100 rounded-lg min-w-max pl-4 py-4 inline-flex gap-x-4">
              <InstagramIcon fill={"#FF3C5F"} />
              <h4 className="self-center">Instagram</h4>
              <img width={20} src="/imgs/tick.svg" alt="tick" />
            </div>
          ) : (
            instagram.map((acc, index) => {
              if (instapos == index) {
                return (
                  <div className="bg-gray-100 rounded-lg min-w-max pl-4 py-4 inline-flex gap-x-4">
                    <InstagramIcon fill={"#FF3C5F"} />
                    <h4 className="self-center">Instagram</h4>
                    <img width={20} src="/imgs/tick.svg" alt="tick" />
                    <div className="flex-grow">
                      <p>Selected</p>
                    </div>
                    <p onClick={removeLoggedIn("instagram" + index)}>Remove</p>
                  </div>
                );
              } else {
                return (
                  <div className="bg-gray-100 rounded-lg min-w-max pl-4 py-4 inline-flex gap-x-4">
                    <InstagramIcon fill={"#FF3C5F"} />
                    <h4 className="self-center">Instagram</h4>
                    <img width={20} src="/imgs/tick.svg" alt="tick" />
                    <div className="flex-grow">
                      <p onClick={_instaAccountSwitch(index)}>Switch</p>
                    </div>
                    <p onClick={removeLoggedIn("instagram" + index)}>Remove</p>
                  </div>
                );
              }
            })
          )}

          <div
            className="bg-gray-100 rounded-lg min-w-max pl-4 py-4 inline-flex gap-x-4"
            id="linkedin"
          >
            <LinkedInIcon fill={"#4D669B"} />
            <h4 className="self-center">LinkedIn</h4>
          </div>
          <div
            className="bg-gray-100 rounded-lg min-w-max pl-4 py-4 inline-flex gap-x-4"
            onClick={_twitterOauth}
          >
            <TwitterIcon fill={"#109CF1"} />
            <h4 className="self-center">Twitter</h4>
            {twitter ? <img width={20} src="/imgs/tick.svg" alt="tick" /> : ""}
            <div className="flex-grow"></div>
          </div>
          <p onClick={removeLoggedIn("twitter")}>Remove</p>
          <div
            className="bg-gray-100 rounded-lg min-w-max pl-4 py-4 inline-flex gap-x-4"
            id="pinterest"
          >
            <PinterestIcon fill={"#CE000A"} />
            <h4 className="self-center">Pinterest</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;
